<template>
    <img class="burger-nav-button" src="../assets/menu-icons/Burger_menu.svg" v-on:click="toggleMenu()"/>
</template>

<script>

export default {
    methods: {
        toggleMenu: function () {
            this.$store.commit('toggleNav');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.burger-nav-button{
    width: 24px;
    height: 24px;
    position: absolute;
    margin: 22px;
}
</style>