import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from '../views/Login.vue';
import store from '../store/index.js';
import loadInitialState from '../store/loadInitialState.js';

Vue.use(VueRouter);

const routes = [
    {
        path:  '/login',
        name:  'login',
        component:  Login
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            requiresAuth: true
    },
    },
    {
        path: "/check",
        name: "Check",
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check.vue")
            
    },
    {
        path: "/check/endcontroll",
        name: "endcontroll",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/End.vue")
    },
    {
        path: "/check/form/:id",
        name: "default",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/Default.vue")
    },
    {
        //task notification that has no submission yet
        path: "/check/task/:formid/:taskid",
        name: "task",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/Tasks.vue")
    },
    { 
        //task notification that already has a submission
        path: '/docu/task/:formid/:taskid/:isPersonal', 
        name: "DocuDetail",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuDetail.vue")
    },
    {
        path: "/check/tasks/:formid/:taskid",
        name: "tasks",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/PersTasks.vue")
    },
    {
        path: "/check/task/:taskid",
        name: "taskPreFormId",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/LoadingScreen.vue")
    },
    {
        path: "/check/lkw",
        name: "lkw",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/LKW.vue")
    },
    /*{
        path: "/check/checklist",
        name: "checklist",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import("../views/Check/CheckList.vue")
    },
    {
        path: "/check/faultlist",
        name: "faultlist",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import("../views/Check/Defects.vue")
    },
    {
        path: "/check/todolist",
        name: "todolist",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import( "../views/Check/ToDo.vue")
    },*/
    {
        path: "/check/time",
        name: "Time",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Check/Time.vue")
    },
    {
        path: "/docu",
        name: "Docu",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Docu.vue")
    },
    {
        path: "/docu/tours",
        name: "DocuTours",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuTours.vue")
    },
    {
        path: "/docu/tasks/:taskid?",
        name: "DocuPersonalTasks",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuPersonalTasks.vue")
    },
    {
        path: "/personaldata",
        name: "PersonalData",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/PersonalData.vue")
    },
    // dynamic segments start with a colon
    { 
        path: '/docu/:id', 
        name: "DocuDetailId",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuDetail.vue")
    },
    { 
        path: '/docu/submission/:subid', 
        name: "DocuDetailSubid",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuDetail.vue")
    },
    { 
        path: '/docu/task/:subid', 
        name: "DocuDetailTaskSubid",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/DocuDetail.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
});

//check if user is logged in and allowed to see route before each page request
router.beforeEach((to, from, next) => {
    loadInitialState().then(function(){
        if(to.matched.some(record => record.meta.requiresAuth)) {
            if (store.state.isLoggedIn) {
                //reset token to header after refresh
                store.commit('auth_reset_at_refresh');
                next();
            }else{
                console.log("isLoggedIn false");
                next('/login');
            }
        } else {
            next();
        }
    }).catch((error) =>{
        console.log("STORE NOT INITIALIZED in index.js!!: ",error);
    });
})

export default router;
