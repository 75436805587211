import moment from 'moment';
export default {
    methods: {
        convertDateTimeToString(APITime){
            var date = moment.utc(APITime).local().format();
            return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + (""+date.getFullYear()).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
        },
        convertDateTimeStringToDate(date){
            return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + (""+date.getFullYear()).slice(-2);
        },
        convertAPIDateTimeToString(APITime){
            //APITime format: 2021-02-16T09:02:03.000Z
            var localTime = moment.utc(APITime).local().format();
            var pieces = localTime.split("T");
            var datePieces = pieces[0].split("-");
            var time = pieces[1].substring(0,5);
            return datePieces[2]+'.'+datePieces[1]+'.'+datePieces[0]+' '+time;
        },
        convertTimestamp(timestamp){
            // Create a new JavaScript Date object based on the timestamp
            var date = new Date(timestamp);
            // Hours part from the timestamp
            var hours = date.getHours();
            // Minutes part from the timestamp
            var minutes = "0" + date.getMinutes();
            // Seconds part from the timestamp
            //var seconds = "0" + date.getSeconds();

            // Will display time in 10:30:23 format
            var formattedTime = hours + ':' + minutes.substr(-2);
            return formattedTime;
        }
    }
}