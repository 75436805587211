<template>
    <router-link :to="destination">
        <genButton :imgSrc='imgSrc'></genButton>
    </router-link>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
export default {
    props:{
        imgSrc:String,
        destination:String,
    },
    components: {
    'genButton':Button
  },
}; 
</script>