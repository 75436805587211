<template>
    <div class="header-wrap">
        
        <div class="row-one row">
            <span class="headline">{{headline}}</span><br>
            <div v-if='objectNotEmpty(rowOne)' class="row-one row">
                <span class="name">{{rowOne.userName}}</span><br>
                <span class="company">{{companyName}}</span><br>
            </div>
        </div>
        <div v-if='objectNotEmpty(rowTwo)' class="row-two row" :class='{ "expandable": rowTwo.expandable }' v-on:click='rowTwo.onClick ? rowTwo.onClick() : {}'>
            <div v-if='objectNotEmpty(rowTwo.colOne)' class="col-one col">
                <span class="head">{{rowTwo.colOne.head}}</span>
                <span class="content">{{rowTwo.colOne.content}}</span>
                <span class="foot">{{rowTwo.colOne.foot}}</span>
            </div>
            <div v-if='objectNotEmpty(rowTwo.colTwo)' class="col-two col">
                <span class="head">{{rowTwo.colTwo.head}}</span>
                <span class="content">{{rowTwo.colTwo.content}}</span>
                <span class="foot">{{rowTwo.colTwo.foot}}</span>
            </div>
            <div v-if='objectNotEmpty(rowTwo.colThree)' class="col-three col">
                <span class="head">{{rowTwo.colThree.head}}</span>
                <span class="content">{{rowTwo.colThree.content}}</span>
                <span class="foot">{{rowTwo.colThree.foot}}</span>
            </div>
        </div>
        <div v-if='objectNotEmpty(rowThree) && rowThree.active' class="row-three row">
            <div v-if='objectNotEmpty(rowThree.colOne)' class="col-one col">
                <span class="light-head">{{rowThree.colOne.head}}</span>
                <trafficlight class='wide' v-bind:status='rowThree.colOne.status'></trafficlight>
                <span class="foot">{{rowThree.colOne.foot}}</span>
            </div>
            <div v-if='objectNotEmpty(rowThree.colTwo)' class="col-two col">
                <span class="light-head">{{rowThree.colTwo.head}}</span>
                <trafficlight class='wide' v-bind:status='rowThree.colTwo.status'></trafficlight>
                <span class="foot">{{rowThree.colTwo.foot}}</span>
            </div>
            <div v-if='objectNotEmpty(rowThree.colThree)' class="col-three col">
                <span class="light-head">{{rowThree.colThree.head}}</span>
                <trafficlight class='wide' v-bind:status='rowThree.colThree.status'></trafficlight>
                <span class="foot">{{rowThree.colThree.foot}}</span>
            </div>
        </div>
        <div class="data-row"></div>
    </div>
</template>

<script>

import TrafficLight from "@/components/TrafficLight.vue";
export default {
    data(){
        return{
            version: process.env.VUE_APP_VERSION,
        }
    },
    props:{
        headline:String,
        rowOne:{
            userName:String,
            company:Object,
        },
        rowTwo:{
            colOne:{
                head:String,
                content:String,
                foot:String,
            },
            colTwo:{
                head:String,
                content:String,
                foot:String,
            },
            colThree:{
                head:String,
                content:String,
                foot:String,
            },
            onClick:Function,
            expandable: {
                type: Boolean,
                default: false,
            }
        },
        rowThree:{
            active:Boolean,
            colOne:{
                head:String,
                status:Number,
                foot:String
            },
            colTwo:{
                head:String,
                status:Number,
                foot:String
            },
            colThree:{
                head:String,
                status:Number,
                foot:String
            }
        }
    },
    methods: {
        objectNotEmpty(obj) {
            if(obj){
                return Object.keys(obj).length !== 0;
            }else{
                return false;
            }
        },
    },
    computed:{
        companyName: function(){
            if(this.rowOne.company != undefined){
                return this.rowOne.company.company_name;
            }
            return "";
        },

    },
    components: {
        'trafficlight':TrafficLight,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/styles.scss";
.header-wrap{
    min-height: 30px;
    padding:10px;
    color:$fontColorLight;
    background: linear-gradient(128deg, $bgColorDark 0%, $bgColorMed 100%);
    .headline, a{
        font-size: 1.5rem;
        color:$fontColorLight;
        text-decoration: none;
    }
    .name{
        font-size: 2rem;
    }
}
.row{
    padding:10px 0;
}
.expandable{
    cursor: pointer;
}
.row-two, .row-three{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border-top:1px solid $fontColorHighlightedOpaque;
    width: 80vw;
    margin: auto;
    .col{
        display:flex;
        flex-direction: column;
        margin:auto;
        flex-basis: 33%;
        .head, .foot{
            font-size:.8rem;
        }
        .content{
            padding: 2px 0;
            font-size:1.6rem;
            line-height: .8;
        }
    }
}
@media all and (max-width: 450px){
    .row-two, .row-three{
        width: 95vw;
        .col{
            .content{
                font-size:1.4rem;
                line-height: .8;
            }
        }
    }
}
.light-head{
    font-size: 2rem;
}
</style>