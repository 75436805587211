<template>
        <div id="menu" v-on:click="toggleMenu()">
            <div id="burger-nav-content">
                <router-link class="truecon-text-link" to="/">
                    <img class="logo" alt="" src="../assets/cluevo_readyCheck.svg" />
                </router-link>
                <router-link to="/">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_home.svg" />
                    <span>{{$t('navigation.overview')}}</span>
                </router-link>
                <router-link to="/docu">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_dok.svg" />
                    <span>{{$t('navigation.documentation')}}</span>
                </router-link>
                <router-link to="/personaldata">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Persönliche_Daten.svg" />
                    <span>{{$t('navigation.personalData')}}</span>
                </router-link>
                <router-link v-if='tourActive' to="/check">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Abfahrk.svg" />
                    <span>{{$t('navigation.tour')}}</span>
                </router-link>
                <div class="abortTour" v-if='tourActive' v-on:click='clickAbortTour()'>
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Tour Abbruch.svg" />
                    <span>{{$t('navigation.abortTour')}}</span>
                </div>
                <router-link v-else to="/check/lkw">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Abfahrk.svg" />
                    <span>{{$t('navigation.startTour')}}</span>
                </router-link>
                <router-link v-if="false" to="/check/time">
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Nachtrag.svg" />
                    <span>{{$t('navigation.report')}}</span>
                </router-link>

                <div class="logout" v-on:click='clickLogout()'>
                    <img class="menuicon" alt="" src="../assets/menu-icons/SIDEBAR_Abschluss.svg" />
                    <span>{{$t('navigation.logout')}}</span>
                </div>
                <span class="appversion">v {{version}}</span>
            </div>
            <confirmpopup :active='this.confirmActive' :msg='$t("messages.confirmLogout")' :confirmed='this.confirm' :declined='this.decline'></confirmpopup>
            <confirmpopup :active='this.confirmAbortActive' :msg='$t("messages.confirmAbortTour")' :confirmed='this.confirmAbort' :declined='this.declineAbort'></confirmpopup>
            
        </div>
</template>

<script>
export default {
    name: "BurgerMenu",
    data(){
        return{
            confirmAbortActive:false,
            confirmActive: false,
            version: process.env.VUE_APP_VERSION
        }
    },
    computed:{
        tourActive(){
            return this.$store.state.local.curCheck.curTourId >= 0;
        }
    },
    methods: {
        toggleMenu: function () {
            if(!this.confirmActive && !this.confirmAbortActive){
                this.$store.commit('toggleNav');
            }
        },
        clickLogout: function () {
            this.confirmActive = true; 
        },
        decline:function(){
            this.confirmActive = false; 
        },
        confirm: function(){
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push('/login')
            })
        },
        clickAbortTour: function () {
            this.confirmAbortActive = true; 
        },
        declineAbort:function(){
            this.confirmAbortActive = false;
        },
        confirmAbort: function(){
            console.log("ABORT TOUR!")
            this.confirmAbortActive = false;
            this.$store.dispatch('abortTour')
            .then(() => {
                if (this.$route.path != '/check/lkw/') {
                    this.$router.push('/check/lkw/')
                }
            })
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
#menu{
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left:0;
    padding-top:25px;
    background: rgb(2,0,36);
    background: linear-gradient(128deg, $bgColorDark 0%, $bgColorMed 100%);
    //background: linear-gradient(128deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 55%, rgba(17,0,255,1) 100%);
}
#burger-nav-content{
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100vh;
    padding:20px;
    img{
        margin:10px;
    }
    .logo{
        width: 180px;
        margin: auto;
    }
    a, .logout, .abortTour{
        display: flex;
        text-decoration: none;
        color: $fontColorLight;
        align-items: center;
    }
    /*a:hover, .logout:hover{
        color:#3c3c3c;
    }*/
    .abortTour{
        cursor: pointer;
    }
    .logout{
        //add media query for small height screens
        cursor: pointer;
        bottom: 50px;
        position: absolute;
    }
    .truecon-text-link{
        margin:0 0 50px 0;
        font-size: 1.5rem;
    }
}
.menuicon{
    width:40px;
}
.appversion{
    font-size: .7em;
    position: absolute;
    bottom: 3px;
    right: 15px;
    color:$fontColorLight;
}
</style>