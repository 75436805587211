import localForage from 'localforage';


const store = localForage.createInstance({
  name: 'app',
});
store.setDriver(store.INDEXEDDB);

const mapStateForCache = (state) => {
  let filteredState = {...state};
  delete filteredState.isNavOpen;
  delete filteredState.isNotificationsOpen;
  return filteredState;
};

export const setState = async (state) => {
  const cachedState = mapStateForCache(state);
  return await store.setItem('state', cachedState);
};

export const getState = () => store.getItem('state');

export const deleteLocalForageInstance = () => {
  console.log("store: ",store)
  if(store.dropInstance({name: 'app'}) !== null){
    var response = store.dropInstance({name: 'app'})
    console.log("response:" ,response)
  }
  return response;
}

export const deleteState = () => store.removeItem('state');