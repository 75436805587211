<template>
  <div class="languageSelectWrap">
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectLocale',
  data() {
    return { langs: ['en', 'de'] }
  }
}
</script>

<style scoped>
.languageSelectWrap{
    width: 45px;
    float: left;
    position: absolute;
    right: 20px;
    top: 25px;
}
</style>