<template>
    <div class="form-wrapper">
        <div class="form-wrapper-title">
            {{title}}
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props:{
        title:String
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.form-wrapper{
    width: 90vw;
    padding:20px;
    margin: 20px auto 60px auto;
    border-radius: 10px;
    background-color: $bgColorWhite;
    display: flex;
    flex-direction: column;
    .form-wrapper-title{
        color:$fontColorHighlighted;
        font-weight: bold;
        padding: 10px;
        text-transform: uppercase;
    }
}
</style>