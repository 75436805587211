<template>
    <router-link :to='link' class="nav-icon-wrap" :class='{"noImage":(imgSrc === undefined)}'>
        <img v-if='imgSrc !== undefined' class="nav-icon" :src='imgSrc'/>
        <div :class="['status',status]"></div>
        <span class="text">{{text}}</span>
        <span v-if='text3 !== undefined && text3 !== "" ' class="text3">{{text3}}</span>
        <span v-if='text2 !== undefined && text2 !== "" ' class="text2">{{text2}}</span>
    </router-link>
</template>

<script>

export default {
    props:{
        imgSrc:String,
        link:String,
        text:String,
        text2:String,
        text3:String,
        status:String,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.nav-icon-wrap{
    position: relative;;
    display:inline-flex;
    padding: 10px;
    background-color:$bgColorLight;
    flex: 1 1 40%;

    /*max-width:170px;
    min-width: 135px;
    max-height:170px;
    min-height: 135px;*/

    @media all and (max-width:360px){
        max-width:100%;
        max-height:auto;
    }
    &.noImage{
        display: block;
        text-decoration: none;
        flex:none;
        height: auto;
        color:$fontColorDark;
        padding: 25px;
        max-width:100%;
        .text{
            position:relative;
            float:left;
            width: 100%;
            font-size: 1.6rem;
            bottom: 0;
            margin:10px 0;
        }
        .text2{
            text-align: left;
            width:100%;
            float:left;
            margin-top:10px;
            margin-left:10px;
        }
        .text3{
            width:100%;
            float:left;
        }
    }
    img{
        width: 100%;
        max-height: 130px;
    }
    .text{
        position: absolute;
        width: 100%;
        text-align: center;
        bottom:15px;
        left:0;
        color:$fontColorDark;
    }
    .status{
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        top:20px;
        right:20px;
        background-size: contain;
        &.open{
            background-color: white;
            background-image:url("../assets/view-check/Abfahrk_status_offen.svg");
        }
        &.partial, &.failed,  &.ok{
            background-color: $trafficLightColorYellow;
            background-image:url("../assets/view-check/Abfahrk_status_leichterMangel.svg");
        }
        &.okSent{
            background-color: $trafficLightColorGreen;
            background-image:url("../assets/view-check/Abfahrk_status_erledigt.svg");
        }
        &.warningSent{
            background-color: $trafficLightColorYellow;
            background-image:url("../assets/view-check/Abfahrk_status_leichterMangel.svg");
        }
        &.failedSent{
            background-color: $trafficLightColorRed;
            background-image:url("../assets/view-check/Abfahrk_status_schwererMangel.svg");
        }
        &.sendError{
            background-color: $trafficLightColorRed;
            background-image:url("../assets/view-check/Abfahrk_status_schwererMangel.svg");
        }
    }
}
</style>