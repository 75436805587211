<template>
    <div class="home">
        <appheader 
        :headline='$t("header.title")'
        v-bind:rowOne='{
            userName:fullName,
            company:this.$store.state.user.company
        }'
        v-bind:rowTwo='{
            colOne:{
                head:$t("header.id"),
                content:this.$store.getters.getDaysRemaining("training"),
                foot:$t("header.valid"),
            },
            colTwo:{
                head:$t("header.driversLicense"),
                content:this.$store.getters.getDaysRemaining("license"),
                foot:$t("header.valid")
            },
            colThree:{
                head:$t("header.driverCard"),
                content:this.$store.getters.getDaysRemaining("card"),
                foot:$t("header.valid")
            },
            onClick:this.toggleC95,
            expandable:true,
        }'
        v-bind:rowThree='{
            active:false,
            colOne:{
            head:this.$store.state.user.posDriveChecks,
            status:1
            },
            colTwo:{
            head:this.$store.state.user.neutralDriveChecks,
            status:2,
            },
            colThree:{
            head:this.$store.state.user.negDriveChecks,
            status:3,
            foot:""
            }
        }'
        ></appheader>
        <drop-down-generic :active="this.c95Visible" v-on:click.native="toggleC95()">
            <div class="content-wrap dropdown">
                <div class="license-container">
                    <div class="license-header">
                        <span class="license license-col">{{$t("header.license.license")}}</span>
                        <span class="validity license-col">{{$t("header.validity")}}</span>
                        <span class="next-lesson license-col">{{$t("header.license.nextLesson")}}</span>
                    </div>
                    <div class="license-row" v-for='(entry,index) in this.$store.getters.getLicenses(true)' :key="index">
                        <span class="license license-col ">{{entry.license_type}}</span>
                        <div class="validity license-col">
                            <div>
                                <div v-if="entry.valid_from !== null">{{$t("header.license.from")+" "+entry.valid_from}}</div>
                                <div v-if="entry.valid_to !== null">{{$t("header.license.to")+" "+entry.valid_to}}</div>
                            </div>
                        </div>
                        <div class="next-lesson license-col">
                            {{entry.training_date}}
                        </div>
                    </div>
                </div>
                <div class="card-container">
                    <div class="card-header">
                        <span class="card card-col">{{$t("header.driverCard")}}</span>
                        <span class="card-validity card-col">{{$t("header.validity")}}</span>
                    </div>
                    <div class="card-row" v-for='(cardEntry,cardIndex) in this.$store.getters.getCard(true)' :key="cardIndex">
                        <span class="card card-col ">{{cardEntry.license_type}}</span>
                        <div class="card-validity card-col">
                            <div v-if="cardEntry.value !== null">{{cardEntry.value}}</div>
                            <div v-else>/</div>
                        </div>
                    </div> 
                </div> 
            </div>
        </drop-down-generic>
        <div class="content-wrap">
        <bignavicon v-if="tourActive" :imgSrc='getImgUrl("view-home/Abfahrtskontrolle.svg")' link="/check" :text='$t("navigation.tour")'></bignavicon>
        <bignavicon v-else :imgSrc='getImgUrl("view-home/Abfahrtskontrolle.svg")' link="/check/lkw" :text='$t("navigation.startTour")'></bignavicon>
        <bignavicon :imgSrc='getImgUrl("view-home/Dokumentation.svg")' link="/docu" :text='$t("navigation.documentation")'></bignavicon>
        <bignavicon :imgSrc='getImgUrl("view-home/Abfahrk_pers_Daten.svg")' link="/personaldata" :text='$t("navigation.personalData")'></bignavicon>
        <bignavicon-group
            :lists='getFormsByType("personaltask")'
            :imgSrc='getImgUrl("view-check/form_todolist.svg")'
            :title='$t("navigation.personaltask")'
            :overrideTitle='true'
            :personalTask='true'
            :emptyText='$t("navigation.noPersonalTasks")'>
        </bignavicon-group>
        </div>
        <appfooter></appfooter>
    </div>
</template>

<script>
import DropDownGeneric from "@/components/FormFields/DropDownGeneric.vue";
import BigNavIconGroup from "@/components/BigNavIconGroup.vue";
import { mapGetters } from 'vuex';
export default {
    data:function () {
        return {
            c95Visible:false,
        }
    },
    methods:{
        getImgUrl(img) {
            return require('../assets/'+img)
        },
        toggleC95(){
            if(this.c95Visible==false){
                this.c95Visible = true;
            }
            else{
                this.c95Visible = false;
            }
        },
    },
    computed:{
        fullName:function(){
        if(this.$store.state.user.firstname || this.$store.state.user.lastname){
            return this.$store.state.user.firstname + " " + this.$store.state.user.lastname
        }
        return this.$store.state.user.username;
        },
        tourActive(){
            return this.$store.state.local.curCheck.curTourId >= 0;
        },
        ...mapGetters([
            'getFormsByType',
        ])
    },
    beforeCreate() {
        this.$store.dispatch('getProfileData');
        this.$store.dispatch('getNotifications');
    },
    components: {
        'drop-down-generic':DropDownGeneric,
        'bignavicon-group' : BigNavIconGroup
    },
};
</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.card-container, .license-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:5%;
    margin-bottom:60px;
    color:$fontColorMed;
    .license-row, .license-header, .card-row, .card-header{
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        gap: 0;
        .license-col, .card-col{
            flex-basis: 33%;
            padding: 5px;
        }
        .license{
            flex-basis: 20%;
        }
        .validity{
            flex-basis: 45%;
        }
        .next-lesson{
            flex-basis: 35%;
            text-align: right;
        }
        .card-validity{
            text-align: right;
        }
    }
    .license-header, .card-header{
        font-weight: 600;
    }
    .license-row, .card-row{
        border-bottom: 1px solid $fontColorMedLight;
    }
}
.home .nav-icon-wrap, .home .nav-icon-group{
    flex: 1 1 100%;
    margin:2%;
    max-width: 100%;
    max-height: 150px;
    min-height: 100px;
}
</style>
