<template>
    <div class="dopdown-container">
        <transition name="animate-slide-down">
            <div class="dropdown-content-wrap" v-if=active>
                <div class="dropdown-title">{{dropdownTitle}}</div>
                <div v-if='dropdownText !== undefined && dropdownText !== ""' class="dropdown-text">{{dropdownText}}</div>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'drop-down-generic',
    props: {
        active:Boolean,
        dropdownTitle: String,
        dropdownText: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.dropdown-content-wrap{
    background-color:$bgColorLightLight;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    min-width: 360px;
    z-index: 1000;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    .dropdown-title{
        margin:20px;
    }
    .dropdown-text{
        margin:20px;
    }
}
.animate-slide-down-enter{
  margin-top:-100vh;
}
.animate-slide-down-enter-active{
  transition: margin-top .5s;
}
.animate-slide-down-enter-to{
  margin-top:0;
}
.animate-slide-down-leave-active{
  margin-top:-100vh;
  transition: margin-top .5s;
}
</style>