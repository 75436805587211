import {HTTP, HTTPFILE} from '../helpers/axiosHelper.js';
import Vue from 'vue';

//import  {i18n}  from '@/main.js';
var getCheckResultByFormId = function(state, formId, taskId, isPersonal){
    let form;
    if(formId !== undefined && taskId !== undefined && taskId > 0){
        if(isPersonal){
            form = state.local.personalTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
        }else if(isPersonal === false){
            form = state.local.curCheck.tourTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.formId.toString() === formId.toString() && obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
        }else{
            form = state.local.curCheck.tourTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.formId.toString() === formId.toString() && obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
            if(form.length === 0){
                form = state.local.personalTaskResults.filter(obj => {
                    if(obj.taskId !== undefined){
                        return (obj.taskId.toString() === taskId.toString());
                    }else{
                        return false;
                    }
                });
            }
        }
    }else{
        form = state.local.curCheck.tourCheckResults.filter(obj => {
            if(formId !== undefined && (taskId === undefined || taskId < 0)){
                return obj.formId.toString() === formId.toString()
            }else{
                return false
            }
        })
    }
    return form[0];
};
var getStatusObjectByFormId = function(state, ids, isPersonal){
    let statusObj = {};
    if(ids.formId !== undefined){
        let resultObj = getCheckResultByFormId(state, ids.formId,ids.taskId, isPersonal);
        if(resultObj != undefined){
            statusObj = resultObj.status;
        }
    }
    return statusObj;
};

const loadFromCache = (state, cached) => {
    console.log("loadFromCache");
    if (cached) {

      //filter out key wich schould not be cached (for example status)
      var getCachedOrInitial = function(key,state,cache){
        if(key == 'status' || key == 'keepData'|| key == 'initialized'){
            if(typeof state[key] !== 'object'){
                return state[key];
            }
            return Object.assign({},state[key]);
        }else{
            if(typeof cache[key] !== 'object'){
                if(cache[key] !== 'undefined'){
                    return cache[key];
                }
                else{
                    return state[key];
                }
            }
            return Object.assign({},state[key],cache[key]);
        }
      }

      if(cached.isLoggedIn == true){
        Object.keys(cached).forEach((key) => {
            state[key] = getCachedOrInitial(key,state, cached);
            
        });
      }
    }
    state.initialized = true;
    //state.isLoggedIn = true;
};
const toggleNav = (state) => {
  state.isNavOpen = !state.isNavOpen
};
const toggleNotifications = (state) => {
    state.isNotificationsOpen = !state.isNotificationsOpen
};
const setCurCheckStartTime = (state) =>{
  if(!state.local.curCheck.startTime){
    state.local.curCheck.startTime = new Date();
  }
};
const setTruck = (state, id) => {
    state.local.curCheck.chosenTruck = id;
    
    let statusObjLkw = getStatusObjectByFormId(state,{formId:"lkw"});
    //let statusObjTime = getStatusObjectByFormId(state,{formId:"time"});
    statusObjLkw.fieldStatus = 'ok'
    //set all the form ids for the chosen truck (vehicle forms and vehicle_type_forms)
    var vehicleForms = [...state.vehiclesAPI.vehicles[id].forms, ...state.vehiclesAPI.vehicles[id].vehicle_type_forms];
    var defectsList = vehicleForms.filter(obj => {
        return obj.form_type === 'faultlist';
    })
    if(defectsList[0] !== undefined){
        state.local.curCheck.defectsId.baseId = defectsList[0].form_base_id;
    }
};
const setTrailer = (state, id) => {
  state.local.curCheck.chosenTrailer = id;
  getStatusObjectByFormId(state,{formId:state.local.curCheck.lkwDataId}).fieldStatus = 'ok'
  //state.local.curCheck.checklistStatus[state.local.curCheck.lkwDataId].fieldStatus = 'ok'
};
const setType = (state, id) => {
  state.local.curCheck.vehicleType = id;
};
/*setLkwDataStatus(state,status){
  state.local.curCheck.checklistStatus[state.local.curCheck.chosenTruck].fieldStatus = status;
},*/
const setTimerId = (state, id) => {
  state.local.nachtragTimer.timerId = id;
};
const setFormStatusFields = (state, payload) => {
    let statusObj = getStatusObjectByFormId(state, {formId:payload.formId, taskId:payload.taskId});
    statusObj.fieldStatus = payload.value;
  //state.local.curCheck.checklistStatus[payload.formId].sendStatus = 'open';
};
const setFormStatusSend = (state, payload) => {
    /*if(payload.taskId !== undefined && payload.taskId >= 0){
        let taskSeenNotificationExistsIndex = state.local.seenNotifications.findIndex(obj => {
            if(obj.notification_type === "new-task"){
                return obj.notification_meta.form_task_id === payload.taskId;
            }else{
                return false;
            }
        });
        let taskUnseenNotificationExistsIndex = state.local.unseenNotifications.findIndex(obj => {
            if(obj.notification_type === "new-task"){
                return obj.notification_meta.form_task_id === payload.taskId;
            }else{
                return false;
            }
        });
        if(taskSeenNotificationExistsIndex > -1){
            state.local.unseenNotifications[taskSeenNotificationExistsIndex] = {...state.local.unseenNotifications[taskSeenNotificationExistsIndex],'submission_id':notification.notification_meta.submission_id}
        }
        if(taskUnseenNotificationExistsIndex > -1){
            state.local.unseenNotifications[taskUnseenNotificationExistsIndex] = {...state.local.unseenNotifications[taskUnseenNotificationExistsIndex],'submission_id':notification.notification_meta.submission_id}
        }
    }*/
    let statusObj = getStatusObjectByFormId(state,{formId:payload.formId, taskId:payload.taskId}, payload.isPersonal);
    statusObj.sendStatus = payload.value;
};
const trackTime = (state, payload) => {
  state.local.nachtragTimer.currentlyTracked = payload.type;
  let now = Date.now();
  if(state.local.nachtragTimer.trackedTimesEntries.length-1 >= 0){
    //only set endTime when it was null before, so Values are not overwritten
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime == null){
      state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime = now;
    }
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].geoEnd === undefined){
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].geoEnd = payload.geoLocation;
      }
  }
  let newEntry;
  //TODO: replace with real multilanguage strategy
  var label = {load:'Beladen',unload:'Entladen',drive:'Fahren',border:'Grenze',repair:'Service', pause:'Pause',stand:'Stehzeit',stop:'Stopp'};
  newEntry= {label:label[payload.type], type:payload.type, startTime:now+1, endTime:null, text:payload.text, km:payload.km, geoStart:payload.geoLocation};
  state.local.nachtragTimer.trackedTimesEntries.push(newEntry);
  if(payload.type !== 'stop'){  
    state.local.nachtragTimer.startTimeActualTimer = now/1000;
  }else{
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1] !== undefined){
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1].endTime=now+1;
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1].geoEnd=payload.geoLocation;
    }
    state.local.nachtragTimer.startTimeActualTimer = -1;
    //TODO: send status schould not be ok, because not sent... refactor, maybe in /check check if "time" for Bericht status
    getStatusObjectByFormId(state,{formId:"time"}).sendStatus = "sent";
    getStatusObjectByFormId(state,{formId:"time"}).fieldStatus = "ok";
  }
};
const clearTimers = (state) => {
  state.local.nachtragTimer.trackedTimesEntries = [];
};
const setCurTourId = (state,tourId) => {
  state.local.curCheck.curTourId = tourId;
};
//refactor! only pass optionindex and get the option that should be shown directly from store
const setOptionsPopup = (state, payload) => {
    if(payload !== undefined){
        if(payload.field && payload.selectedOptionIndex !== undefined){
            state.status.optionsPopup.field = payload.field;
            state.status.optionsPopup.taskId = payload.taskId;
            state.status.optionsPopup.option = payload.selectedOptionIndex;
            state.status.optionsPopup.active = true;
            return;
        }
    }
    state.status.optionsPopup.active = false;
}
const setLockPopup = (state, payload) => {
    if(payload !== undefined){
        if(payload.field !== undefined){
            state.status.lockPopup.field = payload.field;
            state.status.lockPopup.type = payload.type;
            state.status.lockPopup.active = true;
            return;
        }
    }
    state.status.lockPopup.active = false;
}
const checkQrValue = (state,payload) => {
    if(payload.qrValue !== undefined){
        if(payload.field !== undefined){
            let form = getCheckResultByFormId(state, payload.field.formId, payload.isPersonal)
            if(form){
                if(form.fields[payload.field.fieldId]){
                    if(form.fields[payload.field.fieldId].qrValue === payload.qrValue){
                        form.fields[payload.field.fieldId].lockedBy = "unlocked";
                        state.status.lockPopup.active = false;
                    }
                }
            }
        }
    }
}
const setFieldResult = (state, payload) => {
    /********
     * handle the actual field of of the payload
     */
    let form = getCheckResultByFormId(state, payload.formId, payload.taskId, payload.isPersonal);
    let statusObj = getStatusObjectByFormId(state, {formId:payload.formId, taskId:payload.taskId}, payload.isPersonal);
    if(form){
        if(form.fields[payload.fieldId]){
            let field = form.fields[payload.fieldId];
            if(payload.value === ""){
                field.complete = false;
            }else{
                field.complete = true;
            }
            //set the fields value and the selected=true in the option (options[trueOption]) 
            if(['radio','boolean','select'].includes(field.type.type)){
                //if options array is empty set value directly to false
                if(field.options && field.options.length > 1){
                    if(['boolean'].includes(field.type.type)){
                        //set option with type "option" true, because it can't have a textvalue etc.
                        if(field.options[field.trueOption].value === null && field.options[field.trueOption].type === "option"){
                            field.options[field.trueOption].value = true;
                        }
                        field.selectedOption = field.trueOption;
                        field.setSeverity = 0;
                    }
                    if(payload.value === true){
                        //if both options have same severity, choose one
                        if(field.trueOption === field.falseOption){
                            field.falseOption = 1;
                            field.trueOption = 0;
                        }
                        field.options[field.trueOption].selected = true;
                        field.options[field.falseOption].selected = false;
                        field.selectedOption = field.trueOption;
                        field.value = field.trueOption;
                        field.setSeverity = 0;
                    }else if(payload.value === false){
                        //if both options have same severity, choose one
                        if(field.trueOption === field.falseOption){
                            field.falseOption = 0;
                            field.trueOption = 1;
                        }
                        let maxSeverity = Math.max(field.options[field.falseOption].severity, field.severity)
                        field.options[field.falseOption].selected = true;
                        field.options[field.trueOption].selected = false;
                        field.selectedOption = field.falseOption;
                        field.value = field.falseOption;
                        field.setSeverity = maxSeverity !== undefined && maxSeverity !== null ? maxSeverity : 2;
                    }else{
                        form.fields[payload.fieldId].value = payload.value;
                        if(['radio','select'].includes(field.type.type)){
                            field.selectedOption = payload.index;
                            field.options[payload.index].selected = true;
                            field.options.forEach((option,key) => {
                                if(key !== payload.index){
                                    option.selected = false;
                                }
                            })
                        }
                    }
                }else{
                    field.value = payload.value;
                }
            }else if(['file'].includes(field.type.type)){
                field.value = payload.value;
                field.selectedOption = 0;
            }else if(['signature'].includes(field.type.type)){
                //only allow one signature to be saved, if we want to save them all use field.fileObjects.push({"fileObject":payload.fileObject, "status":"unsent"});
                field.fileObjects = [{"fileObject":payload.fileObject, "status":"unsent"}];
                field.selectedOption = 0;
                field.value = payload.value;
            }else if(['number'].includes(field.type.type)){
                field.setSeverity = 0;
                if(field.severity){
                    let severity0 = field.severity.filter(e => {
                        return e.severity === 0;
                    });
                    let severity1 = field.severity.filter(e => {
                        return e.severity === 1;
                    });
                    /*let severity2 = field.severity.filter(e =>{
                        return e.severity = 2;
                    });*/
                    if(severity0 !== undefined && (payload.value > severity0[0].max || payload.value < severity0[0].min)){
                        field.setSeverity = 1;
                    }
                    if(severity1 !== undefined && (payload.value > severity1[0].max || payload.value < severity1[0].min)){
                        field.setSeverity = 2;
                    }
                }
                field.value = payload.value;
            }else{
                field.value = payload.value;
            }
        }else{
            console.log("form(payload.formId)[payload.fieldId]: should not reach this. all fields should be initialized!")
        }
    }else{
        console.log("error at form(payload.formId). should not reach this. all fields should be initialized!")
    }
    //check if other complete or values are to set
    let formComplete = true;
    let groupComplete = true;
    //groupTotalComplete is true when all fields are set, also the non required ones
    let groupTotalComplete = true;
    let res = true;
    /********
     * handle the groupcheck => check all children
     */ 
    //iterate all results
    let maxFormSeverity = 0;
    Object.keys(form.fields).forEach((key) => {
        let elem = form.fields[key];
        
        //set the maxFormSeverity if its higher than the one before
        if(maxFormSeverity < elem.setSeverity){
            maxFormSeverity = elem.setSeverity;
        }
        //if the payload field is a group, set all values of its children
        if(payload.type === 'group'){
            if(elem.parentId === payload.fieldId){
                if(payload.value === true){
                    //if radio buttons should automatically select the least severe option when parent is clicked => add here to array in if
                    if(['checkbox','boolean'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            if(['boolean'].includes(elem.type.type)){
                                //set option with type "option" true, because it can't have a textvalue etc.
                                if(elem.options[elem.trueOption].value === null && elem.options[elem.trueOption].type === "option"){
                                    elem.options[elem.trueOption].value = true;
                                }
                            }
                            //set the selected property of the option to true
                            elem.options[elem.trueOption].selected = true;
                            //set the element value true, only used for displaying boolean
                            elem.value = elem.trueOption;
                            elem.setSeverity = 0;
                        }else{
                            elem.value = true;
                            elem.setSeverity = 0;
                        }
                        elem.complete = true;
                    }
                    if(['radio'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            elem.options.forEach((option) => {
                                option.selected = false;
                                elem.setSeverity = 0;
                            });
                            elem.value = "";
                        }else{
                            elem.value = "";
                        }
                        elem.complete = true;
                    }
                }else{
                    if(['checkbox','radio','boolean'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            if(elem.options[elem.trueOption].value === null){
                                elem.options[elem.trueOption].value = true;
                            }
                            elem.options.forEach((option) => {
                                option.selected = false;
                            });
                            elem.value = "";
                        }else{
                            elem.value = "";
                        }
                        elem.complete = false;
                    }
                }
            }
        //if the field is a boolean, check if all siblings are allready set, if not -> complete is false
        //also check if one of the required values is false -> the group is false
        }else {
        //else if(payload.type === 'boolean'){
            if(elem.parentId === payload.parentId){
                if(elem.skip === false){
                    if(elem.complete !== true){
                        groupTotalComplete = false;
                        if(elem.required === true){
                            groupComplete=false;
                        }
                    }
                    //can happen if all options are true
                    if(elem.options != undefined && elem.options.length <= 1 && elem.type !== 'group' && elem.value === false){
                        res=false;
                    }
                    if(elem.falseOption !== elem.trueOption){
                        if(elem.value === elem.falseOption){
                            res=false;
                        }
                    }
                }
            }
        }
        
        //the formComplete is false as long as there is at least one (none skipped) required field not complete
        if(elem.skip === false){
            if(elem.complete !== true && elem.required){
                formComplete = false;
            }
        }
    });
    /********
     * handle and set the group complete state itself (above only its children are set)
     */
    if(payload.type === 'group'){
        if(payload.value === true){
            form.fields[payload.fieldId].complete = true;
        }else{
            form.fields[payload.fieldId].complete = false;
        }
    //set the group itself to complete if the current field is the last field in the group
    }else{
        if(groupComplete === true){
            if(payload.type === 'boolean'){
                //only check it for fields that are inside a group and have a parentId
                if(form.fields[payload.parentId] !== undefined){
                    form.fields[payload.parentId].value = res;
                    form.fields[payload.parentId] = {...form.fields[payload.parentId], ...{complete:true}};
                }
            }
            if(groupTotalComplete === true){
                form.fields[payload.parentId] = {...form.fields[payload.parentId], ...{complete:true}};
            }
        }else{
            if(payload.parentId != 0){
                form.fields[payload.parentId].complete = false;
            }
        }
    }
    /*****
     * handle the form state
     */
     statusObj.sendStatus = 'open';
     if(formComplete && res && maxFormSeverity <= 0){
        statusObj.fieldStatus = 'ok'
     }else if((formComplete && !res) || maxFormSeverity >= 2){
        statusObj.fieldStatus = 'failed'
     }
     else{
        statusObj.fieldStatus = 'partial'
     }
     if(maxFormSeverity === 1){
        statusObj.fieldStatus = 'warning'
     }
};
const setFieldOptionResult = (state,payload) => {
    console.log("setFieldOptionResult payload: ",payload)
    //set value and complete state when field exists
    //payload:{'formId':this.formId,'fieldId':this.fieldId,'value':this.initialValues, 'parentId':this.parentId, type:'text'}
    let form = getCheckResultByFormId(state, payload.formId, payload.taskId, payload.isPersonal);
    if(form){
        if(form.fields[payload.fieldId]){
            form.fields[payload.fieldId].options[payload.optionKey].value = payload.value;
            //this.getCheckResultByFormId(payload.formId)[payload.fieldId].options[payload.optionKey].fileUrl = payload.fileUrl;
        }
        if(payload.fileObject !== undefined){
            if(Array.isArray(form.fields[payload.fieldId].options[payload.optionKey].fileObjects)){
                form.fields[payload.fieldId].options[payload.optionKey].fileObjects.push({"fileObject":payload.fileObject, "status":"unsent"});
            }else{
                form.fields[payload.fieldId].options[payload.optionKey].fileObjects = [];
                form.fields[payload.fieldId].options[payload.optionKey].fileObjects.push({"fileObject":payload.fileObject, "status":"unsent"});
            }
        }
    }
};
const deleteOptionFile = (state,payload) => {
    let form = getCheckResultByFormId(state, payload.formId, payload.taskId, payload.isPersonal);
    if(form){
        if(form.fields[payload.fieldId]){
            if(Array.isArray(form.fields[payload.fieldId].options[payload.optionKey].fileObjects)){
                if(form.fields[payload.fieldId].options[payload.optionKey].fileObjects[payload.fileKey]){
                    form.fields[payload.fieldId].options[payload.optionKey].fileObjects[payload.fileKey].status = "deleted";
                }
            }
        }
    }
};
const setServerFileId = (state,payload) => {
    let form = getCheckResultByFormId(state, payload.formId, payload.taskId, payload.isPersonal);
    if(form){
        if(form.fields[payload.fieldId]){
            if(form.fields[payload.fieldId].options[payload.optionKey] !== undefined){
                form.fields[payload.fieldId].options[payload.optionKey].fileObjects[payload.localFileId].serverFileId = payload.serverFileId;
                form.fields[payload.fieldId].options[payload.optionKey].fileObjects[payload.localFileId].status = "sent";
            }else{
                form.fields[payload.fieldId].fileObjects[payload.localFileId].serverFileId = payload.serverFileId;
                form.fields[payload.fieldId].fileObjects[payload.localFileId].status = "sent";
            }
        }
    }
};
const auth_request = (state) => {
  state.status.value = 'loading'
};
const auth_success = (state, tokens) => {
  state.status.value = 'success'
  state.tokens.accessToken = tokens.accessToken
  if(tokens.refreshToken){
    state.tokens.refreshToken = tokens.refreshToken
  }
    HTTP.defaults.headers.common['readycheck-client'] = "version: " + process.env.VUE_APP_VERSION;
    HTTPFILE.defaults.headers.common['readycheck-client'] = "version: " + process.env.VUE_APP_VERSION;
  if (state.tokens.accessToken) {
    state.isLoggedIn = true;
    HTTP.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
    HTTPFILE.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
  } else {
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
  }
};
const auth_reset_at_refresh = (state) => {
  if (state.tokens.accessToken) {
    HTTP.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
    HTTPFILE.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
  } else {
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
  }
};
const auth_error = (state) => {
    state.status.value = 'error'
    state.tokens.accessToken = ''
    state.isLoggedIn = false;
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
};
const api_error = (state) => {
    state.status.value = 'error'
  //HTTP.defaults.headers.common['authorization'] = null;
};
const refreshToken = (state, response) => {
    state.tokens.accessToken = response.accessToken;
};
const update_notifications = (state,notificationData) => {
    let seen = [];
    let unseen = [];
    /*if(notificationData.rows !== undefined){
        notificationData.rows.forEach((obj) => {
            if(obj.date_seen !== null){
                //check if element exists in seenNotifications (.some faster than filter, because it stops the iteration when a match occurs)
                if (!state.local.seenNotifications.some(e => e.notification_id === obj.notification_id)) {
                    seen.push(obj);
                }
            }else{
                //check if element exists in unseenNotifications (.some faster than filter, because it stops the iteration when a match occurs)
                if (!state.local.unseenNotifications.some(e => e.notification_id === obj.notification_id)) {
                    unseen.push(obj);
                }
            }
        });
        state.local.unseenNotifications = [...state.local.unseenNotifications, ...unseen];
        state.local.seenNotifications = [...state.local.seenNotifications, ...seen];
    }*/
    //TODO: temporary overwrite all notifications on every call, because its not possible to only get unread at the moment
    if(notificationData.rows !== undefined){
        notificationData.rows.forEach((obj) => {
            if(obj.date_seen !== null){
                seen.push(obj);
            }else{
                unseen.push(obj);
            }
        });
        state.local.unseenNotifications = unseen;
        state.local.seenNotifications = seen;
    }
};
const update_notificationReplies = (state,notificationData) => {
    //let replies = [];
    if(notificationData.rows !== undefined){
        notificationData.rows.forEach((obj) => {
            //check if element exists in seenNotifications (.some faster than filter, because it stops the iteration when a match occurs)
            /*if (!state.local.notificationReplies.some(e => e.parent_id === obj.parent_id)) {
                if(obj.notification_type === "reply"){
                    replies.push(obj);
                }
            }*/
            //let index = state.local.unseenNotifications.findIndex(e => e.parent_id === obj.parent_id)
            state.local.unseenNotifications.push(obj)
        });
        //state.local.unseenNotifications = [...state.local.notificationReplies, ...replies];
    }
};
const update_notificationDetails = (state,notificationDetail) => {
    if(notificationDetail !== undefined){
        let index = state.local.unseenNotifications.findIndex(e => e.notification_id === notificationDetail.notification_id)
        Vue.set(state.local.unseenNotifications, index, notificationDetail);
        //state.local.unseenNotifications = [...state.local.notificationReplies, ...replies];
    }

}
const update_unseen_notifications = (state,notificationData) => {
    state.local.unseenNotifications = {...state.local.notifications, ...notificationData.rows}
};

const markAsSeen = (state, id) =>{
    //check if the notification allready exists in seenNotifications
    let seenNotificationsExists = state.local.seenNotifications.filter(obj => {
        return obj.notification_id === id;
    });
    //if not existent, push to seenNotifications and delete in unseenNotifications
    let newSeenEntry = state.local.unseenNotifications.filter(obj => {
        return obj.notification_id === id;
    });
    if (seenNotificationsExists.length <= 0 && state.local.unseenNotifications.length >= 0) {
        state.local.seenNotifications.push(newSeenEntry[0]);
        state.local.unseenNotifications.splice(state.local.unseenNotifications.indexOf(newSeenEntry[0]), 1);
    }
};
const notification_seen = (state, ids) =>{
    if(ids instanceof Array){
        ids.forEach(id => {
            markAsSeen(state,id);
        });
    }else{
        markAsSeen(state,ids);
    }
};
const notification_delete = (state, ids) =>{
    if(ids !== undefined){
        if(ids instanceof Array){
            let toDelete = state.local.seenNotifications.filter(obj => {
                return ids.includes(obj.notification_id);
            });
            toDelete.forEach(notification => {
                state.local.seenNotifications.splice(state.local.seenNotifications.indexOf(notification), 1);
            });
        }else{
            let toDelete = state.local.seenNotifications.filter(obj => {
                return obj.notification_id === ids;
            });
            state.local.seenNotifications.splice(state.local.seenNotifications.indexOf(toDelete[0]), 1);
        }
    }
};
const setNotificationReplie = (state, reply) =>{
        if(reply !== undefined){
            state.local.unseenNotifications.unshift(reply)
        }
}
const update_me = (state,profileData) => {
    state.user = {...state.user,...profileData};
    //let firstName = [];
    //let lastName = [];
    let idNr = [];
    let idValidDate = [];
    let persData;
    let persId;
    //let persSettings;
    if(profileData.meta.groups !== undefined){
        //get the metagroups by key
        persData = profileData.meta.groups.filter(obj => {
            return obj.key === "persData";
        });
        persId = profileData.meta.groups.filter(obj => {
            return obj.key === "persId";
        });

        //store the meta fields in vars
        if(persData[0] !== undefined){
            /*firstName = persData[0].fields.filter(obj => {
                return obj.meta_key === 'firstname'
            });
            lastName = persData[0].fields.filter(obj => {
                return obj.meta_key === 'lastname'
            });*/
            idNr = persId[0].fields.filter(obj => {
                return obj.meta_key === 'idNr'
            });
            idValidDate = persId[0].fields.filter(obj => {
                return obj.meta_key === 'idDate'
            });
        }
    }
    //state.user.lastName = lastName[0] !== undefined && lastName[0].meta_value !== null ? lastName[0].meta_value : "";
    //state.user.firstName = firstName[0] !== undefined && firstName[0].meta_value !== null ? firstName[0].meta_value : "";
    state.user.idNr = idNr[0] !== undefined && idNr[0].meta_value !== null ? idNr[0].meta_value : "";
    state.user.idValidDate = idValidDate[0] !== undefined && idValidDate[0].meta_value !== null ? idValidDate[0].meta_value : null;
    

    //TODO: this is just a temproary hack to disable timetracking via company metafield, later use features array provided by API
    let companyData;
    let disableTimer;
    let enableToDos;
    let enableNotificationReplies;
    if(profileData.company.meta.groups !== undefined){
        //get the metagroups by key
        companyData = profileData.company.meta.groups.filter(obj => {
            return obj.key === "tmpAppFeatures";
        });
    }
    //store the meta fields in vars
    if(companyData[0] !== undefined){
        disableTimer = companyData[0].fields.filter(obj => {
            return obj.meta_key === 'disableTimer'
        });
        enableToDos = companyData[0].fields.filter(obj => {
            return obj.meta_key === 'enableToDos'
        });
        enableNotificationReplies = companyData[0].fields.filter(obj => {
            return obj.meta_key === 'enableNotificationReplies'
        });
    }
    if(disableTimer !== undefined && disableTimer[0] !== undefined){
        if(disableTimer[0].meta_value === true){
            state.features.featureArray = state.features.featureArray.filter(function(value){ 
                return value !== 'time';
            });
        }
    }
    if(enableToDos !== undefined && enableToDos[0] !== undefined){
        if(enableToDos[0].meta_value === false){
            state.features.featureArray = state.features.featureArray.filter(function(value){ 
                return value !== 'toDo';
            });
        }
    }
    if(enableNotificationReplies !== undefined && enableNotificationReplies[0] !== undefined){
        if(enableNotificationReplies[0].meta_value === false){
            state.features.featureArray = state.features.featureArray.filter(function(value){ 
                return value !== 'notificationReplies';
            });
        }
    }
};
const update_vehiclelist = (state, vehicleList) => {
  if(vehicleList){
    var lkwList = vehicleList.filter(obj => {
      return obj.is_trailer === 0
    })
    if(lkwList){
      lkwList.forEach((lkw)=>{
        let newEntry = { [lkw.vehicle_id] : lkw }
        state.vehiclesAPI.vehicles = {...state.vehiclesAPI.vehicles, ...newEntry}
      });
    }
  }
};
const update_trailerlist = (state, trailerList) => {
  let newEntry = { [0] : {vehicle_id:0,license_plate:"noTrailerSlug"}};
  state.vehiclesAPI.trailers = {...state.vehiclesAPI.trailers, ...newEntry}
  if(trailerList){
    trailerList.forEach((trailer)=>{
      newEntry = { [trailer.vehicle_id] : trailer }
      state.vehiclesAPI.trailers = {...state.vehiclesAPI.trailers, ...newEntry}
    });
  }
};
const update_finishedtasklist = (state, taskList) => {
    if(taskList !== undefined && taskList.pagination !== undefined){
        state.local.documentation.finishedTasksData.totalEntries = taskList.pagination.total;
        state.local.documentation.finishedTasksData.pageCount = taskList.pagination.pages;
        let newEntry = { [taskList.pagination.page] : taskList.rows }
        
        state.local.documentation.finishedTasks = {...state.local.documentation.finishedTasks, ...newEntry};
        taskList.rows.forEach(function(tour,index){
            var taskMaxSeverity = -1;
            if(Array.isArray(tour.error_status) && tour.error_status !== null && tour.error_status !== undefined){
                tour.error_status.forEach(function(error){
                    if(error.severity !== undefined && error.severity > taskMaxSeverity){
                        taskMaxSeverity = error.severity;
                    }
                })
            }
            state.local.documentation.finishedTasks[taskList.pagination.page][index] = {...state.local.documentation.finishedTasks[taskList.pagination.page][index], ...{status:taskMaxSeverity}};
        });
      }
};
//merge update_finishedtaskdetails and update_finishedtaskdetails_global and save both in state.docuDetail
const update_finishedtaskdetails = (state, taskDetails) => {
    if(taskDetails){
        state.local.documentation.tourDetails.submissions = [taskDetails.submission];
        state.local.documentation.tourDetails.vehicle = taskDetails.vehicle;
        state.local.documentation.tourDetails.secondaryVehicles = taskDetails.secondary_vehicles;
        state.local.documentation.tourDetails.timers = taskDetails.timers;
    }
}
const update_tourlist = (state, tourList) => {
    //TODO check if tourList.pagination !== null
  if(tourList){
    state.local.documentation.finishedToursData.totalEntries = tourList.pagination.total;
    state.local.documentation.finishedToursData.pageCount = tourList.pagination.pages;
    let newEntry = { [tourList.pagination.page] : tourList.rows }
    state.local.documentation.finishedTours = {...state.local.documentation.finishedTours, ...newEntry};
    tourList.rows.forEach(function(tour,index){
        var tourMaxSeverity = -1;
        if(Array.isArray(tour.error_status) && tour.error_status !== null && tour.error_status !== undefined){
            tour.error_status.forEach(function(error){
                if(error.severity){
                    error.severity.forEach(function(severity){
                        if(severity.severity > tourMaxSeverity){
                            tourMaxSeverity = severity.severity;
                        }
                    })
                }
            })
        }
        state.local.documentation.finishedTours[tourList.pagination.page][index] = {...state.local.documentation.finishedTours[tourList.pagination.page][index], ...{status:tourMaxSeverity}};
    });
  }
};
const update_tourdetails = (state, tourDetails) => {
    if(tourDetails){
        state.local.documentation.tourDetails.submissions = tourDetails.submissions;
        state.local.documentation.tourDetails.vehicle = tourDetails.vehicle;
        state.local.documentation.tourDetails.secondaryVehicles = tourDetails.secondary_vehicles;
        state.local.documentation.tourDetails.timers = tourDetails.timers;
    }
};
const set_active_doc_page = (state, page) => {
  state.local.documentation.finishedToursData.activePage = page;
};
const set_active_task_doc_page = (state, page) => {
    state.local.documentation.finishedTasksData.activePage = page;
  };
const update_docu_image = (state, payload) => {
    if(payload == undefined){
        state.local.documentation.currentShownImages = [];
    }else{
        state.local.documentation.currentShownImages= [...state.local.documentation.currentShownImages, payload];
    }
};
const add_checklist = (state,checklistData) => {
    //save the different types of forms to the right place, forms that belong to a tour go into curCheck, personal tasks go one level higher
    if(checklistData){
        if(checklistData.submissions !== undefined && checklistData.submissions.length > 0){
            let index = state.local.documentation.finishedTasks.findIndex(form => {
                return form.form_task_id === checklistData.task.form_task_id && form.form_id === checklistData.form_id;
            })
            if(index < 0){
                state.local.documentation.finishedTasks.push(checklistData);
            }
        }else{
            if(checklistData.form_type === "task" && checklistData.task !== undefined){
                if(checklistData.task.vehicle_id !== null){
                    let index = state.local.curCheck.tourTasks.findIndex(form => {
                        return form.form_task_id === checklistData.task.form_task_id && form.form_id === checklistData.form_id;
                    })
                    if(index < 0){
                        state.local.curCheck.tourTasks.push(checklistData);
                    }
                }else{
                    let index = state.local.personalTasks.findIndex(form => {
                        return form.task.form_task_id === checklistData.task.form_task_id && form.form_id === checklistData.form_id;
                    })
                    if(index < 0){
                        state.local.personalTasks.push(checklistData);
                    }
                }
            }else{
                let index = state.local.curCheck.tourCheckLists.findIndex(form => {
                    return form.form_id === checklistData.form_id;
                })
                if(index < 0){
                    state.local.curCheck.tourCheckLists.push(checklistData);
                }
            }  
        }
    }
};
const resetChecklistStatus = (state, ids) => {
    state.local.curCheck.checklistStatus[ids.formId] = {formId:ids.formId, taskId:ids.taskId, fieldStatus:"open", sendStatus:"open"}
};
const initCheckResults = (state, payload) => {
    //generate the structure for the checkResultNew object
    let newEntry;
    if(payload){
        /* TODO form_id as key change
        if(state.local.curCheck.curCheck.tourCheckResults[payload.form_id] === undefined){
            newEntry = {[payload.form_id] :{}};
            state.local.curCheck.tourCheckResults = {...state.local.curCheck.tourCheckResults, ...newEntry};
        }*/
        let fields = {}
        payload.form_fields.forEach((field)=>{
            var label = '';
            var name = '';
            let truestOptionIndex = 0;
            let falsestOptionIndex = 0;
            let minSeverity = Infinity;
            let maxSeverity = 0;
            let value;
            let type;
            let severity;
            let setSeverity = 0;
            //TODO: remove -> temporarily made a field QR locked to test
            /*if(payload.form_slug === "abfahrtskontrolle"){

                if([3].includes(field.form_field_id)){
                    lockedBy = "qr";
                    qrValue = "Abblendlicht LKW B555";
                }
            }*/
            if(field.field_label){
                label = field.field_label;
            }
            if(field.field_name){
                name = field.field_name;
            }
            if(field.field_type){
                type = field.field_type;
            }
            if(field.severity){
                severity = field.severity;
            }
            //value of the field itself is an array of the selected options, here i check all these options that are the default value -> checked
            if(field.options !== null && Array.isArray(field.options) && field.options.length > 0){
                value = [];
                field.options.map((elem,index) => {
                    //initialize the fileUrl property, so it will later be reactive...
                    /*if(elem.type === "file"){
                        Vue.set(elem, 'fileUrl', "")
                    }*/
                    if(elem.type === "file"){
                        Vue.set(elem, 'fileObjects', [])
                    }
                    if(elem.isDefault === true){
                        elem.selected = true;
                        value.push(index);

                    }else{
                        elem.selected = false;
                    }
                    return elem;
                });
                var i;
                for (i = 0; i < field.options.length; i++) {
                    if(field.options[i].severity < minSeverity){
                        minSeverity = field.options[i].severity;
                        truestOptionIndex = i;
                    }
                }
                var n;
                for (n = 0; n < field.options.length; n++) {
                    if(field.options[n].severity > maxSeverity){
                        maxSeverity = field.options[n].severity;
                        falsestOptionIndex = n
                    }
                }
                if(maxSeverity == minSeverity){
                    var l;
                    for (l = 0; l < field.options.length; l++) {
                        if(field.options[l].value === "completed"){
                            truestOptionIndex = l
                        }
                    }
                    var m;
                    for (m = 0; m < field.options.length; m++) {
                        if(field.options[m].value !== "completed"){
                            falsestOptionIndex = m
                        }
                    }
                }
                //get the "true" and "false" Value of the field from the severity of the option
                //,4 is just a default value so that reduce works with empty arrays
                /*truestOptionIndex = field.options.reduce(function(prev, curr, index) {
                    return prev.severity < curr.severity ? {...prev,...{option:index-1}} : {...curr,...{option:index}}
                },4).option;
                falsestOptionIndex = field.options.reduce(function(prev, curr, index) {
                    return prev.severity > curr.severity ? {...prev,...{option:index-1}} : {...curr,...{option:index}}
                },0).option;*/
            }else{
                value = field.field_value;
            }
            newEntry = {[field.form_field_id]: {
                name:name,
                label:label,
                parentId:field.parent_id,
                fieldId:field.form_field_id,
                level:field.level,
                type:type,
                value:value,
                trueOption:truestOptionIndex,
                falseOption:falsestOptionIndex,
                selectedOption:-1,
                options:type.type !== 'file' ? field.options : [{type:'file', selected:'true', fileObjects:[]}],
                required:field.required || false,
                complete:false,
                lockedBy:field.codelock || false,
                qrValue:field.form_code,
                skip:field.skip || false,
                severity:severity,
                setSeverity:setSeverity,
                fileObjects:[]
            }};
            //fields.push(newEntry)
            fields = {...fields, ...newEntry};
        })
        let mandatory = false;

        if(["checklist", "endcontroll", "todolist", "task"].includes(payload.form_type)){
            //TODO: this check can be deleted when fixed todolist form is removed
            if(payload.form_type === "todolist" && !state.features.featureArray.includes("ToDo")){
                mandatory = false;
            }else{
                mandatory = payload.mandatory;
            }
        }
        if(payload.task !== undefined && payload.task.form_task_id !== undefined){
            //form is a Task
            if(payload.task.vehicle_id !== null && payload.task.vehicle_id !== undefined){
                //form is a tour Task
                //check if formresult already exists
                let index = state.local.curCheck.tourTaskResults.findIndex(form => {
                    return form.formId === payload.form_id && form.taskId === payload.task.form_task_id;
                })
                if(index < 0){
                    //if it does not exist push it to the results array
                    state.local.curCheck.tourTaskResults.push({
                        formId:payload.form_id,
                        taskId:payload.task.form_task_id,
                        "fields":fields,
                        status:{
                            fieldStatus:'open', 
                            sendStatus:payload.task.task_status === "completed" ? 'sent' : 'open', 
                            mandatory:mandatory
                        },
                        docuDetails:{
                            submission:[],
                            vehicle:null,
                            secondaryVehicles:null
                        }
                    });   
                }
            }else{
                //form is a personal Task
                //check if formresult already exists
                let index = state.local.personalTaskResults.findIndex(form => {
                    return form.formId === payload.form_id && form.taskId === payload.task.form_task_id;
                })
                if(index < 0){
                    //if it does not exist push it to the results array
                    state.local.personalTaskResults.push({
                        formId:payload.form_id,
                        taskId:payload.task.form_task_id,
                        "fields":fields,
                        status:{
                            fieldStatus:'open', 
                            sendStatus:payload.task.task_status === "completed" ? 'sent' : 'open', 
                            mandatory:mandatory
                        },
                        docuDetails:{
                            submission:[],
                            vehicle:null,
                            secondaryVehicles:null
                        }
                    });
                }
            }
        }else{
            //form is NOT a task
            //check if formresult already exists
            let index = state.local.curCheck.tourCheckResults.findIndex(form => {
                return form.form_id === payload.form_id;
            })
            if(index < 0){
                //if it does not exist push it to the results array
                state.local.curCheck.tourCheckResults.push({
                    formId:payload.form_id, 
                    "fields":fields,
                    status:{fieldStatus:'open', sendStatus:'open', mandatory:mandatory}
                });
            }
        }
    }
};
const setTaskDocuDetails = (state,task) => {
    console.log("task in setTaskDocuDetails: ",task);
    let taskDetails =
    {
        submissions:[task.submission],
        vehicleId:task.vehicle_id

    }
    if(task.vehicle_id !== null){
        //task is tour/vehicle task
        let resultObj = getCheckResultByFormId(state, task.form.form_id, task.form_task_id, false)
        resultObj.docuDetails = taskDetails;
    }else{
        //task is personal task
        let resultObj = getCheckResultByFormId(state, task.form.form_id, task.form_task_id, true)
        resultObj.docuDetails = taskDetails;
    }
};
const saveSentDefect = (state,sentDefect) => {
    let fields = [];
    var defectExists = false;
    Object.keys(sentDefect.fields).forEach((key) => {
        let field = sentDefect.fields[key];
        if(field.value !== null && field.value !== undefined && field.value !== ""){
            defectExists = true;
            if(["radio","boolean"].includes(field.type.type)){
                if(field.options !== undefined && field.options.length > 0){
                    //TODO: language!
                    
                    if(field.options[field.value] !== undefined){
                        fields.push({label:field.label,value:field.options[field.value].label[0].value ? field.options[field.value].label[0].value : field.options[field.value].value});
                    }
                }
            }else if(field.type.type === "text"){
                //TODO: language!
                fields.push({label:field.label,value:field.value});
            }
        }
    });
    if(defectExists === true && fields.length > 0){
        state.local.sentDefects.push(fields);
    }
};
const set_defects_checklistId = (state,data) => {
  if(data){
    state.local.curCheck.defectsId.formId = data.form_id;
    state.initialized = true;
  }
};
//TODO: push new messages into array, handle them one after another and show then for the specific time
const set_status_msg = (state, status) => {
  if(status){
    state.status.footerMsg.text = status.text;
    state.status.footerMsg.type = status.type;
  }
};
const set_status_msg_active = (state, active) => {
  state.status.footerMsg.active = active;
};
const finishCurCheck = (state) => {
    //var dateFormat = require('dateformat');
    let d = new Date();
    state.local.curCheck.tourCheckLists = [],
    state.local.curCheck = {
        lkwDataId:'lkw',
        timeId:'time',
        defectsId:{
            formId:-1,
            baseId:-1,
        },
        startTime:d,
        chosenTruck: -1,
        chosenTrailer: -1,
        curTourId: -1,
        tourTasks: [],
        tourTaskResults: [],
        tourCheckLists: [],
        tourCheckResults: [
            {formId:"lkw" , status:{fieldStatus:'open', sendStatus:'open', mandatory:true}},
            {formId:"time", status:{fieldStatus:'open', sendStatus:'open', mandatory:false}}
        ]
    };
    
    state.local.sentDefects = [];
};
const deleteAccessToken = (state) => {
  state.tokens.accessToken = '';
};
const logout = (state, keepData) => {
  if(keepData === true){
    state.keepData = true;
  }else{
    state.keepData = false;
  }
  //TODO: maybe delete the whole store here?
  state.isLoggedIn = false;
  state.isNavOpen = false;
  state.status.value = '';
  state.tokens.accessToken = '';
  HTTP.defaults.headers.common['authorization'] = null;
  HTTPFILE.defaults.headers.common['authorization'] = null;
};
const hardLogout = (state) => {
    state.initialized = false;
    state.isLoggedIn = false;
    state.keepData = false;
    state.tokens = {
        accessToken:'',
        refreshToken:''
    };
    state.features={
        //featureArray:['time',"timeCountry","timeMileage","toDo","notifications","notificationReplies"],
        featureArray:state.features.featureArray
    };
    state.status={
        value:'',
        footerMsg:{
            text:'',
            //error, warning, success, neutral: 
            type:'neutral',
            active:false,
        },
        optionsPopup:{
            active:false,
            field:{},
            option:-1,
        },
        lockPopup:{
            active:false,
            field:{},
            type:"",
        },
        docuDetail:{
            active:false,
            submissions:[],
            vehicle:{},
            secondaryVehicles:[],
            timers:[]
        },
        refreshCount:0,
    };
    state.isNavOpen=false;
    state.isNotificationsOpen=false;
    state.user={
        firstname:'',
        lastname: '', 
        street: '/',
        nr: '/',
        plz: '/',
        city: '/',
        email: '/',
        count: 0,
        licenseDate: 324,
        preDriveChecks: 0,
        posDriveChecks: 0,
        neutralDriveChecks: 0,
        negDriveChecks: 0,
        languageCode:0,
    };
    state.vehiclesAPI={
        vehicles:{},
        trailers:{},
    };
    state.local = {
        sentDefects:[],
        nachtragTimer:{
            currentlyTracked:'none',
            trackedTimesEntries:[],
            timerId:0,
            startTimeActualTimer: -1,
            country:undefined,
        },
        curCheck:{
            lkwDataId:'lkw',
            timeId:'time',
            defectsId:{
                baseId:-1,
                formId:-1
            },
            tourCheckLists:[],
            tourCheckResults:[
                {formId:"lkw" , status:{fieldStatus:'open', sendStatus:'open', mandatory:true}},
                {formId:"time", status:{fieldStatus:'open', sendStatus:'open', mandatory:false}}
            ],
            tourTasks:[],
            tourTaskResults:[],
            startTime:undefined,
            chosenTruck: -1,
            chosenTrailer: -1,
            curTourId: -1,
            vehicleType:1,
        },
        personalTaskResults:[],
        personalTasks:[],
        documentation:{
            finishedTours:{},
            finishedToursData:{
                activePage:1,
                pageCount:1
            },
            finishedTasks:{},
            finishedTasksData:{
                activePage:1,
                pageCount:1
            },
            tourDetails:{
                submissions:[],
                vehicle:{},
                secondaryVehicles:[],
                timers:[]
            },
            currentShownImages:[]
        },
        unseenNotifications:[],
        seenNotifications:[],
    };
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
};
const setRefreshCount = (state,clear) => {
  if(clear){
    state.status.refreshCount = 0;
  }else{
    state.status.refreshCount = state.status.refreshCount +1;
  }  
};

const setAdress = (state, adress) => {
    state.local.nachtragTimer.country = adress.countryCode;
    state.local.nachtragTimer.city = adress.city;
};
const unSetAdress = (state) => {
    state.local.nachtragTimer.country = undefined;
};

export default{
    setRefreshCount,
    logout,
    hardLogout,
    deleteAccessToken,
    finishCurCheck,
    set_status_msg_active,
    set_defects_checklistId,
    add_checklist,
    resetChecklistStatus,
    initCheckResults,
    setTaskDocuDetails,
    saveSentDefect,
    set_status_msg,
    update_trailerlist,
    update_vehiclelist,
    update_finishedtasklist,
    update_finishedtaskdetails,
    update_tourlist,
    update_tourdetails,
    set_active_doc_page,
    set_active_task_doc_page,
    update_notifications,
    update_notificationReplies,
    update_notificationDetails,
    update_unseen_notifications,
    notification_seen,
    notification_delete,
    setNotificationReplie,
    update_me,
    auth_error,
    api_error,
    refreshToken,
    auth_reset_at_refresh,
    auth_success,
    auth_request,
    setOptionsPopup,
    setLockPopup,
    checkQrValue,
    setFieldResult,
    setFieldOptionResult,
    setCurTourId,
    clearTimers,
    trackTime,
    setFormStatusSend,
    setFormStatusFields,
    setTimerId,
    setType,
    setTrailer,
    setTruck,
    setCurCheckStartTime,
    toggleNav,
    toggleNotifications,
    loadFromCache,
    setAdress,
    unSetAdress,
    setServerFileId,
    deleteOptionFile,
    update_docu_image,
}