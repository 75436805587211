<template>
    <div class="hint-wrap">
        <p class="hint-text">
            <slot></slot>
        </p>
    </div>
</template>

<script>

export default {
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.hint-wrap{
    background-color:$bgColorWhite;
    border-radius: 0 0 10px 10px;
    width:90vw;
    padding:20px;
    margin:auto;
    text-align: left;
    font-style:italic;
    .hint-text{
        margin: 0;;
    }
}
</style>