import axios from 'axios';

/*SoNo User API Authentication with JWT and Axios*/

console.log("NODE_ENV: ",process.env.NODE_ENV);
console.log("process.env.VUE_APP_API_ENDPOINT: ",process.env.VUE_APP_API_ENDPOINT);
console.log("readyCheckConfig.generalAPI: ",readyCheckConfig.generalAPI);
console.log("readyCheckConfig.fileAPI: ",readyCheckConfig.fileAPI);

const HTTP = axios.create({
    baseURL: readyCheckConfig.generalAPI,
    //baseURL: 'http://localhost:3000'
    //baseURL: 'https://truecon.dev.cluevo.at'
})

const HTTPGEO = axios.create({
    baseURL: 'https://api.bigdatacloud.net/data/',
})

const HTTPFILE = axios.create({
    baseURL: readyCheckConfig.fileAPI,
})

export default HTTP;
export {HTTPGEO, HTTP, HTTPFILE};