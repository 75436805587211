import { i18n } from '@/main.js'
//helperfunctions for Time calculations
var sumDeltaTime = (entries,type) => {
if(entries!=undefined){
    let driveEntries = entries.filter(function(obj) {
        if(obj.type !== type) {
            return false;
        }
        return true;
        });
        if(driveEntries.length > 0){
            return driveEntries.map(deltaTime).reduce(sum);
        }
        else{
            return 0;
        }
    }
}
var deltaTime = (item) => {
    let deltaTime = 0;
    if(item.endTime!=null){
        deltaTime = (item.endTime-item.startTime)/1000;
    }
    return deltaTime;
};
var sum = (prev,next) => {
    return prev+next;
};
var convertTimeString = (APITime) => {
    return ("0" + APITime.getDate()).slice(-2) + "." + ("0"+(APITime.getMonth()+1)).slice(-2) + "." + (""+APITime.getFullYear()).slice(-2) + " " + ("0" + APITime.getHours()).slice(-2) + ":" + ("0" + APITime.getMinutes()).slice(-2);
}

//getters
const noUnsentDataExists = (state) => {
    if(state.local.nachtragTimer.trackedTimesEntries.length == 0 && state.local.curCheck.curTourId == -1){
        return true;
    }
    return false;
};
const getDaysRemaining = (state) => (dateName) => {
    let nearestDateObj;
    let nearestDate;
    switch (dateName){
        case "license":
            if(state.user.license !== undefined && state.user.license !== null){
                nearestDateObj = state.user.license.reduce(function(prev, curr) {
                    if(curr.valid_to !== null){
                    return prev.valid_to < curr.valid_to ? prev : curr;
                    }
                    return prev;
                });
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.valid_to
        break;
        case "card":
            if(state.user.card !== undefined && state.user.card !== null){
                nearestDateObj = state.user.card[4];
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.value;
        break;
        case "id":
            if(state.user.idValidDate !== undefined && state.user.idValidDate !== null){    
                nearestDateObj = state.user.idValidDate;
            }
            nearestDate = nearestDateObj
        break;
        case "training":
            if(state.user.license !== undefined && state.user.license !== null){
                nearestDateObj = state.user.license.reduce(function(prev, curr) {
                    if(curr.training_date !== null){
                    return prev.training_date < curr.training_date ? prev : curr;
                    }
                    return prev;
                });
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.training_date
        break;
    }
    if(nearestDate !== null && nearestDate !== undefined){
        let nearestDateTimestamp = new Date(nearestDate+" 00:00:00".split(' ').join('T')).getTime()
        let days = Math.round((nearestDateTimestamp - Date.now())/(1000*3600*24));
        let years = 0;
        let ret = "0";
        if(days >= 365){
            years = Math.floor(days / 365);
            if(years > 1){
                ret = ">" + years + " " + i18n.t("general.years");
            }else{
                ret = ">" + years + " " + i18n.t("general.year");
            }
        }else{
            if(days > 1 || days < -1){
                ret = days + " " + i18n.t("general.days");
            }else{
                ret = days + " " + i18n.t("general.day");
            }
        }
        return ret;
    }
    return "0"
};
const getLicenses = (state) => (onlyValid) => {
    if(state.user.license != undefined && state.user.license != null){
        if(onlyValid){
            return state.user.license.filter(function(license){
                return license.valid_to !== null || license.valid_from !== null || license.training_date !== null;
            });
        }
        else{
            return state.user.license;
        }
    }
    return null;
};
const getCard = (state) => (onlyValid) => {
    if(state.user.card != undefined && state.user.card != null){
        if(onlyValid){
            return state.user.card.filter(function(license){
                return license.value !== null;
            });
        }
        else{
            return state.user.card;
        }
    }
    return null;
};
const getTotalStatus = (state) => {
    let status = 1;
    if(state.local.curCheck.tourCheckResults != undefined){
        //Object.keys(state.local.curCheck.checklistStatus).forEach((key) => {
            state.local.curCheck.tourCheckResults.forEach((elem) => {
            //var elem = state.local.curCheck.checklistStatus[key];
            let statusObj = elem.status;
            
            if(statusObj.mandatory){
                if(statusObj.sendStatus === 'open' || statusObj.fieldStatus === 'open'){
                    status = Math.max(status,2);
                }
                if(statusObj.sendStatus === 'failed' || statusObj.fieldStatus === 'sent'){
                    status = Math.max(status,3);
                }
                if(statusObj.sendStatus === 'error'){
                    status = Math.max(status,4);
                }
            }
        });
    }
    return status;
};
const driveCheckStartDateTime = (state) => {
    let d = state.local.curCheck.startTime;
    if(d != undefined){
        return convertTimeString(d);
        //return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + (""+d.getFullYear()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }else{
        return "";
    }
    //return state.local.curCheck.startTime.split(" ")[0].substr(0,5);
};
const driveCheckStartDate = (state) => {
    let d = state.local.curCheck.startTime;
    if(d != undefined){
        return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + (""+d.getFullYear()).slice(-2);
    }else{
        return "";
    }
};
const truckPlates = (state) => {
    let plates = [];
    Object.keys(state.vehiclesAPI.vehicles).forEach((key) => {
        if(state.vehiclesAPI.vehicles[key])
            plates.push({id:state.vehiclesAPI.vehicles[key].vehicle_id, value:state.vehiclesAPI.vehicles[key].license_plate});
    });
    /*state.vehicles.trucks.forEach( (vehicle) => {
    plates.push({id:vehicle.id, value:vehicle.plate});
    });*/
    return plates;
};
const trailerPlates = (state) => {
    let plates = [];
    Object.keys(state.vehiclesAPI.trailers).forEach((key) => {
        if(state.vehiclesAPI.trailers[key])
            plates.push({id:state.vehiclesAPI.trailers[key].vehicle_id, value:state.vehiclesAPI.trailers[key].license_plate});
    });
    return plates;
};
const currVehiclePlate = (state) => {
    if(state.local.curCheck.chosenTruck === -1){
        return "/";
    }
    return state.vehiclesAPI.vehicles[state.local.curCheck.chosenTruck].license_plate;
};
const currTrailerPlate = (state) => {
    if(state.local.curCheck.chosenTrailer <= 0){
        return "/";
    }
    return state.vehiclesAPI.trailers[state.local.curCheck.chosenTrailer].license_plate;
}
const getFieldsByGroupId = (state) => (groupId) => {
    let fields = [];
    state.local.fieldGroups[groupId].fields.forEach(function(item){
        fields = [...fields,item];
    });
    return fields;
};
const getFieldById = (state) => (fieldId) => {
    if(fieldId in state.local.fields){
    return state.local.fields[fieldId];
    }
    return null;
};
//getter with arguments only possible when returning a function that takes the arguments
const getFieldResult = (state, getters) => (ids, isPersonal) => {
    let form = getters.getCheckResultByFormId(ids.formId,ids.taskId, isPersonal);
    if(form){
        if(ids.fieldId in form.fields){
            let field = form.fields[ids.fieldId];
            if(["boolean"].includes(field.type.type)){
                if(field.options && field.options.length > 1){
                    if(field.trueOption === field.value){
                        return true;
                    }else if(field.falseOption === field.value){
                        return false;
                    }
                }
            }
            return field.value;
        }
        console.log("fieldId not in form.fields: ",form.fields)
    }
    console.log("NO FORM: ",form)
    return '';
};
//should be called getFieldState!?
const getGroupState = (state,getters) => (ids, isPersonal) => {
    let form = getters.getCheckResultByFormId(ids.formId, ids.taskId, isPersonal);
    if(form !== undefined){
        if(form.fields[ids.fieldId] !== undefined){
            return form.fields[ids.fieldId].complete;
        }
    }
    return 'initial';
};

//check if any fields of the group are locked
const getLockedFieldsFromGroup = (state, getters) => (ids,isPersonal) => {
    let locked = [];
    if(ids.formId in state.local.curCheck.tourCheckResults){
        //iterate all children of the group and add locked fieldIds to locked array
        Object.keys(getters.getCheckResultByFormId(ids.formId, ids.taskId, isPersonal).fields).forEach(function(key){
            let field = getters.getCheckResultByFormId(ids.formId,ids.taskId, isPersonal).fields[key];
            if(field.parentId === ids.fieldId){
                if(!(field.lockedBy === false || field.lockedBy === 'unlocked') && field.skip === false){
                    locked.push(field.fieldId);
                }
            }
        });
    }
    return locked;
};

//TODO should use the accessToken state.tokens.accessToken to check if isLoggedIn
//isLoggedIn: state => !!state.tokens.accessToken,
const authStatus = (state) => {
    return state.status.value || ''
};
const getDocuEntries = state => (page) => {
    return state.local.documentation.finishedTours[page];
};
const getDocuTaskEntries = state => (page) => {
    return state.local.documentation.finishedTasks[page];
};
const getTime = state => (type) => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, type)
};
/*const getWorkTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'work')
};
const getPauseTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'pause')
};
const getReadyTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'ready')
};*/

const isFeatureSupportet = (state) => (feature) => {
    if(Array.isArray(state.features.featureArray) && state.features.featureArray.includes(feature)){
        return true;
    }
    return false;
};

const getValueForCurLanguage = () => (values) => {
    //state.languageCode deprecated -> unused -> remove
    if(Array.isArray(values)){
        let val = values.filter(value => value.language === i18n.locale);
        return val[0];
    }else if(typeof values === 'string' || values instanceof String){
        return {value:values};
    }
    if(values !== undefined){
        return values;
    }
    else{
        return "";
    }
};

const getNextRoute = (state) => (actualRoute) => {
    //TODO: mehrere checklisten etc. vorsehen, globale form reihenfolge definieren oder so
    switch(actualRoute){
        case "checklist":
            if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "faultlist":
            if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "todolist":
            if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "time":
            //if(state.local.curCheck.endId.baseId > -1){
                return "/check";
            //}
    }

};
const getTourTaskByTaskId = state => (taskId) => {
    let form = state.local.curCheck.tourTasks.filter(obj => {
        if(taskId !== undefined && taskId >= 0){
            return obj.task.form_task_id.toString() === taskId.toString()
        }
    });
    return form[0];
};
const getPersTaskByTaskId = state => (taskId) => {
    let form = state.local.personalTasks.filter(obj => {
        if(taskId !== undefined && taskId >= 0){
            return obj.task.form_task_id.toString() === taskId.toString()
        }
    });
    return form[0];
};
const getFormByFormId = (state) => (formId, taskId) => {
    let form = state.local.curCheck.tourCheckLists.filter(obj => {
        if(formId !== undefined && (taskId === undefined || taskId < 0)){
            return obj.form_id.toString() === formId.toString()
        }else if(formId !== undefined && taskId !== undefined && obj.task !== undefined && obj.task.form_task_id !== undefined){
            return obj.form_id.toString() === formId.toString() && obj.task.form_task_id === taskId
        }else{
            return false
        }
    })
    return form[0];
};
const getCheckResultByFormId = (state) => (formId,taskId,isPersonal) =>{
    let form;
    if(taskId !== undefined && taskId > -1){
        if(isPersonal){
            form = state.local.personalTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
        }else if(isPersonal === false){
            form = state.local.curCheck.tourTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
        }else{
            form = state.local.curCheck.tourTaskResults.filter(obj => {
                if(obj.taskId !== undefined){
                    return (obj.taskId.toString() === taskId.toString());
                }else{
                    return false;
                }
            });
            if(form.length === 0){
                form = state.local.personalTaskResults.filter(obj => {
                    if(obj.taskId !== undefined){
                        return (obj.taskId.toString() === taskId.toString());
                    }else{
                        return false;
                    }
                });
            }
        }
    }else{
        form = state.local.curCheck.tourCheckResults.filter(obj => {
            if(formId !== undefined && (taskId === undefined || taskId < 0)){
                return obj.formId.toString() === formId.toString()
            }else{
                return false
            }
        })
    }
    return form[0];
};
const getTaskDocuDetail = (state, getters) => (ids, isPersonal) => {
    let docuDetail = getters.getCheckResultByFormId(ids.formId,ids.taskId, isPersonal).docuDetail;
    return docuDetail;
}
const  getStatusObjectByFormId = (state, getters) => (ids, isPersonal) => {
    let statusObj = getters.getCheckResultByFormId(ids.formId,ids.taskId, isPersonal).status;
    return statusObj;
};
/* open, partial, failed, ok */
/* open, sent, error */
const getFormFieldStatus = (state, getters) => (ids,isPersonal) => {
    let resultObj;
    resultObj = getters.getCheckResultByFormId(ids.formId, ids.taskId, isPersonal);
    if(resultObj !== undefined){
        let statusObj = resultObj.status;
        if(statusObj.fieldStatus != undefined){
            return statusObj.fieldStatus;
        }
    }
    else{
        return "open";
    }
};
const getFormStatus = (state, getters) => (ids,isPersonal) => {
    let status = "open";
    if(ids.formId != undefined){
        let results;
        results = getters.getCheckResultByFormId(ids.formId, ids.taskId, isPersonal);
        let statusObj;
        if(results !== undefined){
            statusObj = results.status;
        }
        if(statusObj !== undefined && statusObj.fieldStatus != undefined){
            if(statusObj.sendStatus === "open"){
                status = statusObj.fieldStatus;
            }else if(statusObj.sendStatus === "error"){
                status = "sendError";
            }else if(statusObj.sendStatus === "sent"){
                if(statusObj.fieldStatus === "ok"){
                    status = 'okSent';
                }else if(statusObj.fieldStatus === "warning"){
                    status = 'warningSent';
                }else{
                    status = 'failedSent';
                }
            }
        }
    }
    return status;
};
const getTourTasks = (state) => {
    return state.local.curCheck.tourTasks;
};
const getFormsByType = (state) => (formTypes) => {
    /*let result;
    if(Array.isArray(formTypes)){
        let filteredForms = []; 
        formTypes.forEach(function(formType){
            let forms = [];
            state.local.curCheck.tourCheckLists.forEach((form) => {
                if(formType === "personaltask"){
                    if(form.form_type === "task" && form.task !== undefined && form.task.vehicle_id === null){
                        forms.push(form);
                    }
                }else if(formTypes === "vehicletask"){
                    if(form.form_type === "task" && form.task !== undefined && form.task.vehicle_id !== null){
                        filteredForms.push(form);
                    }
                }else{
                    if(form.form_type === formType){ 
                        forms.push(form);
                    } 
                }
            })
            filteredForms = filteredForms.concat(forms);
            result = filteredForms;
        })
    }else{
        let filteredForms = []; 
        state.local.curCheck.tourCheckLists.forEach((form) => {
            if(formTypes === "personaltask"){
                if(form.form_type === "task" && form.task !== undefined && form.task.vehicle_id === null){
                    filteredForms.push(form);
                }
            }else if(formTypes === "vehicletask"){
                if(form.form_type === "task" && form.task !== undefined && form.task.vehicle_id !== null){
                    filteredForms.push(form);
                }
            }else{
                if(form.form_type === formTypes){
                    filteredForms.push(form);
                }
            }
        })
        result = filteredForms;
    }
    return result;*/
    let forms = [];
    if(Array.isArray(formTypes)){
        formTypes.forEach(function(formType){
            if(formType === "personaltask"){
                forms = state.local.personalTasks.filter(elem => {
                    return elem.task.task_status != "completed";
                });
            }else if(formType === "vehicletask"){
                forms = state.local.curCheck.tourTasks;
            }else{
                state.local.curCheck.tourCheckLists.forEach((form) => {
                    if(form.form_type === formType){
                        forms.push(form);
                    }
                });
            }
        });
    }else{
        if(formTypes === "personaltask"){
            forms = state.local.personalTasks.filter(elem => {
                return elem.task.task_status != "completed";
            });
        }else if(formTypes === "vehicletask"){
            forms = state.local.curCheck.tourTasks;
        }else{
            state.local.curCheck.tourCheckLists.forEach((form) => {
                if(form.form_type === formTypes){
                    forms.push(form);
                }
            });
        }
    }
    return forms;
};
const getFormsByNotType = (state) => (formTypes) => {
    let forms = [];
    if(Array.isArray(formTypes)){
        state.local.curCheck.tourCheckLists.forEach((form) => {
            if(!formTypes.includes(form.form_type)){
                forms.push(form);
            }
        });
    }else{
        state.local.curCheck.tourCheckLists.forEach((form) => {
            if(!form.form_type === formTypes){
                forms.push(form);
            }
        });
    }
    return forms;
};
const getFormIdByBaseId = (state) => (baseId) => {
    let formId = -1;
    state.local.curCheck.tourCheckLists.forEach((form) => {
        if(form.form_base_id === baseId){
            formId = form.form_id;
        }
    })
    return formId;
};
//TODO: use some sort order from the api not this temporary hack
const getFormsInOrder = (state) => {
    //const sortable =  Object.entries(state.local.curCheck.tourCheckLists).sort((a,b) => {
    const sortable =  state.local.curCheck.tourCheckLists.sort((a,b) => {
        // checkliste ist immer an erster stelle
        if(a[1].form_type === "checklist"){
            return -1;
        }else if(b[1].form_type === "checklist"){
            return 1;
        //endcontroll immer an letzter stelle
        }else if(a[1].form_type === 'endcontroll'){
            return 1;
        }else if(b[1].form_type === 'endcontroll'){
            return -1;
        // todolist an 2. stelle
        }else if(a[1].form_type === 'todolist'){
            return -1;
        }else if(b[1].form_type === 'todolist'){
            return 11;
        }
        //rest ist egal
    });
    return sortable;
};
const getRepliesByNotificationId = (state) => (notificationId) =>{
    /*let replies = state.local.notificationReplies.filter(obj => {
    if(notificationId !== undefined && obj.parent_id !== undefined){
        return obj.parent_id === notificationId
    }
    return replies;*/
    let replies = [];
    if(state.local.notificationDetails[notificationId] !== undefined){
        replies = state.local.notificationDetails[notificationId].previous;
        console.log("replies: ",replies);
    }
    return replies;
};
export default{
    noUnsentDataExists,
    getDaysRemaining,
    //getLicenseDaysRemaining,
    //getCardDaysRemaining,
    //getIdDaysRemaining,
    getTotalStatus,
    getFormFieldStatus,
    getFormStatus,
    getCheckResultByFormId,
    getFormByFormId,
    getTourTaskByTaskId,
    getPersTaskByTaskId,
    driveCheckStartDateTime,
    driveCheckStartDate,
    truckPlates,
    trailerPlates,
    currVehiclePlate,
    currTrailerPlate,
    getFieldsByGroupId,
    getFieldById,
    getFieldResult,
    getGroupState,
    getLockedFieldsFromGroup,
    authStatus,
    getDocuEntries,
    getDocuTaskEntries,
    getTime,
    isFeatureSupportet,
    getValueForCurLanguage,
    getNextRoute,
    getStatusObjectByFormId,
    getTaskDocuDetail,
    getLicenses,
    getCard,
    getTourTasks,
    getFormsByType,
    getFormsByNotType,
    getFormIdByBaseId,
    getFormsInOrder,
    getRepliesByNotificationId
    /*getDriveTime,
    getWorkTime,
    getPauseTime,
    getReadyTime,*/
}