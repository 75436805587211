<template>
    <div class="nav-icon-group">
        <bignavicon
            v-if='lists !== undefined && lists.length == 1 && groupIsDefault !== true'
            :class='myclass'
            :key ='lists[0].form_id'
            :imgSrc='imgSrc'
            :link='lists[0].form_type === "task" ? getRoute("/check/"+lists[0].form_type, lists[0].form_id, lists[0].task.form_task_id, personalTask) : getRoute("/check/"+lists[0].form_type, lists[0].form_id)'
            :text='singleTaskTitle'
            :status='getFormStatus({formId:lists[0].form_id, taskId:lists[0].task !== undefined ? lists[0].task.form_task_id : undefined},personalTask)'>
        </bignavicon>
        <bignavicon-norouter
            v-else-if='(lists !== undefined && lists.length > 0) || lists.length == 0'
            :class='myclass'
            :imgSrc='imgSrc' 
            :text='title'
            :status='lists.length == 0 ? "open" : groupStatus'
            v-on:click.native='toggleGroupOpen()'>
        </bignavicon-norouter>
        <drop-down-generic class="nav-group-dropdown" :active="this.groupOpen" :dropdownTitle='this.title' :text='this.text' v-on:click.native="toggleGroupOpen()">
            <div class="sepperator"></div>
            <div v-if='lists === undefined || lists.length <= 0'>
                {{this.emptyText}}
            </div>
            <bignavicon
                v-for='(list,key) in lists'
                :key ='key'
                :imgSrc='list.form_type === "task" ? undefined : getImgUrl("view-check/form_"+list.form_type+".svg")'
                :link='getRoute("/check/"+list.form_type, list.form_id, list.task !== undefined ? list.task.form_task_id : undefined, personalTask)'
                :text='list.form_type === "task" ? getValueForCurLanguage(list.task.task_name).value : getValueForCurLanguage(list.form_title).value'
                :text2='list.form_type === "task" && list.task.date_deadline !== null ? $t("general.dueDate")+" "+convertAPIDateTimeToString(list.task.date_deadline) : ""'
                :text3='list.form_type === "task" ? getValueForCurLanguage(list.task.task_description).value : ""'
                :status='getFormStatus({formId:list.form_id,taskId:list.task !== undefined ? list.task.form_task_id : undefined},personalTask)'>
            </bignavicon>
             <formButton class="backButton" :imgSrc='getImgUrl("Nav_back.svg")'></formButton>
        </drop-down-generic>
    </div>
</template>

<script>
import dateFunctions from '@/mixins/dateFunctions';
import { mapGetters } from 'vuex';
import BigNavIconNoRouter from "@/components/BigNavIconNoRouter.vue";
import DropDownGeneric from "@/components/FormFields/DropDownGeneric.vue";
export default {
    data:function () {
        return {
            groupOpen:false,
        }
    },
    components: {
        'bignavicon-norouter' : BigNavIconNoRouter,
        'drop-down-generic':DropDownGeneric
    },
    props:{
        lists:Array,
        myclass:String,
        imgSrc:String,
        title:String,
        text:String,
        overrideTitle:Boolean,
        optionalForms:Boolean,
        groupIsDefault:Boolean,
        personalTask:Boolean,
        emptyText:String
    },
    methods:{
        //returns the route with form_type as name or that not exists, the default route with form_id
        getRoute:function(route, formId, taskId){
           if(taskId === undefined){
                return "/check/form/"+formId;
            }else if(this.personalTask){
                return "/check/tasks/"+formId+'/'+taskId;
            }else{
                return "/check/task/"+formId+'/'+taskId;
            }
           // }
        },
        getImgUrl(img) {
            let defaultImage = require('@/assets/view-check/form_default.svg')
            try {
                return require('@/assets/'+img);
            } catch (e) {
                return defaultImage
            }    
        },
        toggleGroupOpen:function(){
            if(!this.groupOpen){
                this.$store.dispatch("getTasks");
            }
            this.groupOpen = !this.groupOpen;
        }
    },
    computed:{
        //only applies if there is only one task that is directly shown in /check tiles
        singleTaskTitle:function(){
            if(this.overrideTitle){
                return this.title;
            }else{
                if(this.lists[0].task !== undefined && this.getValueForCurLanguage(this.lists[0].task.task_name).value !== ""){
                    return this.getValueForCurLanguage(this.lists[0].task.task_name).value;
                }else{
                    return this.getValueForCurLanguage(this.lists[0].form_title).value;
                }
            }
        },
        groupStatus:function(){
            let groupStatus = "undefined";
            //check all the forms for their status, and calc the form group status by them (this depend if the forms ar mandatory or not)
            let openExists = false;
            let warningExists = false;
            let partialExists = false;
            let failedExists = false;
            let okSentExists = false;
            this.lists.forEach(form => {
                let taskId = undefined;
                if(form.task !== undefined){
                    taskId = form.task.form_task_id;
                }
                let status = this.getFormStatus({formId:form.form_id, taskId:taskId},this.personalTasks);
                if(status === "open"){
                    openExists = true;
                }
                if(["warningSent"].includes(status)){
                    warningExists = true;
                }
                if(["partial","failed","ok"].includes(status)){
                    partialExists = true;
                }
                if(["failedSent","sendError"].includes(status)){
                    failedExists = true;
                }
                if(["okSent"].includes(status)){
                    okSentExists = true;
                }
            });
            if(this.optionalForms){
                if(okSentExists){
                    groupStatus = "okSent"
                }
                if(warningExists){
                    groupStatus = "warningSent"
                }
                if(partialExists){
                    groupStatus = "partial";
                }
                if(failedExists){
                    groupStatus = "failedSent";
                }
            }else{
                if(warningExists){
                    groupStatus = "warningSent"
                }
                if(partialExists){
                    groupStatus = "partial";
                }
                if(failedExists){
                    groupStatus = "failedSent";
                }
                if(openExists){
                    groupStatus = "open";
                }
                if(!partialExists && !failedExists && !openExists && !this.optionalForms){
                    groupStatus = "okSent"
                }
            }
            return groupStatus;
        },
        ...mapGetters([
            'getValueForCurLanguage',
            'getFormStatus',
        ])
    },
    mixins:[dateFunctions],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/styles.scss";
.nav-icon-group{
    position: relative;
    display: inline-flex;
    background-color: $bgColorLight;
    flex: 1 1 40%;
    margin: 2%;
    max-width: 150px;
    min-width: 150px;
    min-height: 150px;
    @media (max-width: 360px){
        max-width: 100%;
    }
    .nav-icon-wrap{
        margin:0;
        min-width:100%;
        max-width:100%;
        background-color: transparent;
    }
    .nav-group-dropdown .dropdown-content-wrap{
        padding: 50px;
        min-width: 300px;
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 100%;
            margin:0 auto 15px auto;
        }
        .dropdown-title{
            font-weight: bold;
            color: $fontColorMedLight;
            text-transform: none;
            font-size: 1.4rem;
        }
        .nav-icon-wrap{
            min-width:0;
            display:block;
            margin:10px 0;
            max-height:auto;
            background-color: $bgColorLight;
        }
    }
}

</style>