import store from "../store";
import {HTTP,HTTPFILE} from "./axiosHelper.js";
import router from "../router";

export default function setup() {
// Deal with all responses containing a 401 error to try to refresh the access token if possible
    if(HTTP != undefined){
        HTTP.interceptors.response.use(
            // No special handling of responses needed. We return it as it comes in.
            response => {
                return response;
            },
            // This object is not null if an error occured
            error => {
                // Check if it is a 401 Unauthorized error
                if (error.response !== undefined && error.response.status === 401 && error.response.data.status !== false) {
                    console.log("401 API !!!: ",error.response);
                    // Try to refresh the access token
                    //if(store.state.status.refreshCount <= 2){
                    if(error.response.data !== undefined && error.response.data === "token expired"){    
                        return refreshAccessToken()
                        .then(() => {
                            // Was refreshing the access token successfull?
                            //SoNo extract content-type from header and reset it (if i just take the error from the error the invalid token is also re sent endless loop)
                            var contentType = error.config.headers['Content-Type']
                            if(contentType){
                                var headers = {'Content-Type':contentType}
                            }

                            //check if login failed
                            if(error.config.data !== "login failed" && error.config.status !== false){
                                // Repeat the request
                                return HTTP({
                                    method: error.config.method,
                                    url: error.config.url,
                                    data: error.config.data,
                                    headers: headers
                                }).then(result => {
                                    return Promise.resolve(result);
                                }).catch(error => {
                                    return Promise.reject(error, "refresh Token expired");
                                });
                            }
                        })
                        .catch(error => {
                            return Promise.reject(error);
                        });
                    }else{
                        store.commit('logout',false);
                        router.push('/login');
                        return Promise.reject("refresh Token failed after 10 tries!");
                    }
                }
                // No special treatement of any other error
                return Promise.reject(error);
            }
        );
    }
    if(HTTPFILE != undefined){
        HTTPFILE.interceptors.response.use(
            // No special handling of responses needed. We return it as it comes in.
            response => {
                return response;
            },
            // This object is not null if an error occured
            error => {
                if (typeof error.response === 'undefined') {
                    return Promise.reject(Error("A network error occurred."))
                }
                // Check if it is a 401 Unauthorized error
                if (error.response.status === 401 && error.response.data.status !== false) {
                    //if the responseType of the request is arrayBuffer, the error is also an arrayBuffer...
                    //following code decodes the buffer to a string message:
                    console.log("401 File API !!!: ",error.response);
                    let message = String.fromCharCode.apply(
                        null, 
                        new Uint8Array(error.response.data));
                        console.log("401 File API message:", message);
                    // Try to refresh the access token
                    if(error.response.data !== undefined && (error.response.data === "token expired" || message === "token expired")){
                        return refreshAccessToken()
                        .then(() => {
                            // Was refreshing the access token successfull?
                            //SoNo extract content-type from header and reset it (if i just take the error from the error the invalid token is also re sent endless loop)
                            var contentType = error.config.headers['Content-Type']
                            if(contentType){
                                var headers = {'Content-Type':contentType}
                            }
                            var responseType = error.config.responseType;
                            if(responseType){
                                var tmpResponseType = responseType;
                            }else{
                                tmpResponseType = "text";
                            }

                            //check if login failed
                            if(error.config.data !== "login failed" && error.config.status !== false){
                                // Repeat the request
                                return HTTPFILE({
                                    method: error.config.method,
                                    url: error.config.url,
                                    data: error.config.data,
                                    headers: headers,
                                    responseType: tmpResponseType
                                }).then(result => {
                                    return Promise.resolve(result);
                                }).catch(error => {
                                    return Promise.reject(error, "refresh Token expired");
                                });
                            }
                        }).catch(error => {
                            return Promise.reject(error);
                        });
                    }else{
                        store.commit('logout',false);
                        router.push('/login');
                        return Promise.reject(error,"refreshing Token failed!");
                    }
                }
                // No special treatement of any other error
                return Promise.reject(error);
            }
        );
    }
}
function refreshAccessToken(){
    //SoNo, return promise whith result true or false depending on refresh success (maybe a better way to use promise)
    return store.dispatch('refreshToken');
}
