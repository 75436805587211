<template>
    <div class="notification-container">
        <img class="notification-button" src="../assets/menu-icons/Nachrichten.svg" v-on:click="toggleMenu()"/>
        <div v-if='newNotification' class="new-notification"></div>
    </div>
</template>

<script>

export default {
    methods: {
        toggleMenu: function () {
            this.$store.commit('toggleNotifications');
            //TODO: put this somewhere else, every 10 min or so.
            this.$store.dispatch('getNotifications');
        }
    },
    computed:{
        newNotification(){
            //only show the new notification marker when relevant notifications are there (don't show it for form-subs)
            var result = this.$store.state.local.unseenNotifications.filter(obj => {
                return (obj.notification_type !== "form-sub" && obj.date_seen == null);
            })
            if(result.length > 0){
                return true;
            }else{
                return false;
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.notification-container{
    position: absolute;
    margin: 22px;
    left:45px;
}
.notification-button{
    width: 26px;
    height: 26px;
}
.new-notification{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    left:18px;
    top: 18px;
    background-color: $fontColorHighlighted;
}
</style>