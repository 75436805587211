<template>
  <div>
    <div class="login-header">
      <img class="logo" alt="" src="../assets/cluevo_readyCheck.svg" />
    </div>
    <form class="login-form" @submit.prevent="login">
        <div>
            <input class="login-text-field username" :placeholder='this.$t("login.labels.username")' type="text" v-model="username">
        </div>
        <div>
            <input class="login-text-field password" :placeholder='$t("login.labels.password")' type="password" v-model="password">
        </div>
        <div>
            <input class="login-text-field company" :placeholder='$t("login.labels.company")' type="text" v-model="company">
        </div>
        <div>
            <button class="button" type="submit">Login</button>
        </div>
        {{this.$store.state.status.value}}
    </form>
    <appfooter></appfooter>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        username : "",
        password : "",
        company: "",
        device: "device name",
      }
    },
    mounted() {
        if (localStorage.company) {
            this.company = localStorage.company;
        }
    },
    methods: {
      login: function () {
        const username = this.username 
        const password = this.password.trim()
        const company = this.company
        const device = this.device
        this.$store.dispatch('login', { username, company, password, device }).then(() => this.$router.push('/')).catch(err => console.log("LOGIN ERROR: ",err))
      },
    },
    watch: {
        company(newCompany) {
            localStorage.company = newCompany;
        }
    }
  }
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.login-header{
  background: linear-gradient(128deg, $bgColorDark 0%, $bgColorMed 100%);
  width:100%;
  height:40vh;
  float:left;
  .logo{
    width:50%;
    margin: 2vh auto;
    height:36vh;
  }
}
.login-form{
  float:left;
  width: 100%;
  height:60vh;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  .login-text-field, .button{
    border:none;
    background: none;
    width: 80%;
    text-align: center;
    line-height: 2;
    font-size: 1.5em;
    font-weight: 200;
  }
  .login-text-field{
    border-bottom: 1px solid rgba(0,0,0,.1);
    max-width: 400px;
  }
  .button{
    max-width: 300px;
    background-color:$bgColorHighlighted;
    border-radius:25px;
    color: white;
    margin: 60px 0 40px 0;
    cursor:pointer;
  }
}
.login-extra{
    float:left;
    width: 100%;
    .change-pw-link{
        font-size: .7rem;
        max-width: 400px;
        padding:20px 0;
        margin:0 auto;
        cursor:pointer;
    }
}
</style>


