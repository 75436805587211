<template>
    <div class="notifications-list">
        <div v-if='collapsible && this.list.length > 0' class="notifications-title collapsible" @click.stop='collapse()'>
            {{this.title + " ("+this.list.length+")"}}
            <div class="collapseIconWrap">
                <img class="collapseIcon" :class="{'expanded':!collapsed}" alt="" src="../assets/view-check/Nav_carrot inverted.svg" />
            </div>
        </div>
        <div v-else class="notifications-title">
            {{this.title + " ("+this.list.length+")"}}
        </div>
        
        <div class="notifications-collapsible">
            <div ref="collapsible" class="notifications-wrap" :class="{'expanded':!collapsed}">
                <div v-if='this.list.length > 0' class="dismiss-allnotifications" @click.stop="deleteNotification(ids)">{{deleteAllText}}</div>
                <div v-else class="no-notifications">{{$t('notifications.nonotifications')}}</div>
                <transition-group name="fade" tag="div" mode="in-out">
                    <div v-for='notification in list' class="notification" :key='notification.notification_id' :class='"priority" + severity(notification)'>
                        <notification :notification='notification' :deleteFunction='deleteFunction'></notification>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
export default {
    name: "NotificationsMenu",
    data(){
        return{
            collapsed:false,
            answering:false,
        }
    },
    props:{
        list: Array,
        title: String,
        defaultCollapsed: Boolean,
        collapsible: {
            type: Boolean,
            default: true
        },
        colapsible: Boolean,
        deleteFunction: Function,
        deleteAllText: String
    },
    components: {
        'notification':Notification,
    },
    mounted(){
        this.collapsed = this.defaultCollapsed;
    },
    computed:{
        ids(){
            return this.list.map(notification => notification.notification_id);
        },
    },
    methods: {
        collapse(){
            if(!this.collapsed){
                this.$refs['collapsible'].style.height = 0;
                this.collapsed = true;
            }else{
                this.$refs['collapsible'].style.height = 'auto';
                this.collapsed = false;
            }
        },
        deleteNotification(id){
            this.deleteFunction(id);
        },
        severity(notification){
            let severity = 0
            if(notification.severity !== undefined && notification.severity !== -1){
                severity = notification.severity
            }else if(notification.parent_severity !== undefined && notification.parent_severity !== -1){
                severity = notification.parent_severity
            }
            return severity;
        }
    } 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";

    .collapsible{
        cursor: pointer;
    }
    .notifications-collapsible{
        .notifications-wrap.expanded{
            height:auto;
            opacity: 1;
        }
        .notifications-wrap{
            height:0;
            opacity: 0;
            transition: opacity .5s;
            overflow: hidden;
            transition-timing-function: ease-in-out;
        }
    }
    .no-notifications{
        color: $fontColorMedLight;
        text-align: center;
    }
    .dismiss-allnotifications{
        float: left;
        min-width: 90px;
        color: $fontColorLight;
        cursor: pointer;
    }
    
    .truecon-text-link{
        margin:0 0 30px 0;
        font-size: 1.5rem;
    }
    .notifications-title{
        color: $fontColorLight;
        font-size: 1.5rem;
        margin:10px 0;
    }
    .notification{
        display: inline-block;
        width: calc(100% - 12px);
        position: relative;
        margin:12px 0;
        color: $fontColorDark;
        background-color:$bgColorLight;
        padding:5px;
        border-radius: 3px;
        transition: all 1s;
    }
    .notification.priority-1{
        /*border-left: 8px solid $trafficLightColorRed;
        background-color: $trafficLightColorRed;*/
        border-left: 8px solid $trafficLightColorGreen;
    }
    .notification.priority2{
        border-left: 8px solid $trafficLightColorRed;
    }
    .notification.priority1{
        border-left: 8px solid $trafficLightColorYellow;
    }
    .notification.priority0{
        border-left: 8px solid $trafficLightColorGreen;
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .fade-leave-to {
        transform: translateX(-300%);
    }
    .fade-leave-active {
        position: absolute;
    }
    .collapseIconWrap{
            float:right;
            text-align: right;
            .collapseIcon{
            width:20px;
            transition-duration: 0.3s;
            transition-property: transform;
            &.expanded{
                transform: rotate(180deg);
            }
        }
    }
</style>