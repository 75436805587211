<template>
    <div id="notifications" v-on:click="toggleMenu()">
        <div class="overlay"></div>
        <div id="notifications-content">
            <notificationlist 
                :title='this.$t("navigation.notifications")' 
                :list='this.notifications' 
                :defaultCollapsed='false' 
                :collapsible='false' 
                :deleteFunction='this.notificationSeen'
                :deleteAllText='this.$t("notifications.markAllAsSeen")'>
            </notificationlist>
            <notificationlist 
                v-if='(this.submissions.length > 0)'
                :title='this.$t("navigation.submissions")' 
                :list='this.submissions' 
                :defaultCollapsed='true' 
                :deleteFunction='this.notificationSeen'
                :deleteAllText='this.$t("notifications.markAllAsSeen")'>
            </notificationlist>
            <notificationlist 
                :title='this.$t("navigation.seenNotifications")' 
                :list='this.readNotifications' 
                :defaultCollapsed='true' 
                :deleteFunction='this.notificationDelete'
                :deleteAllText='$t("notifications.deleteall")'>
            </notificationlist>
        </div>
    </div>
</template>

<script>

import NotificationList from "@/components/NotificationList.vue";
import { mapGetters } from 'vuex';
export default {
    name: "NotificationsMenu",
    data(){
        return{
            formSubmissionsCollapsed:false,
            height:0,
        }
    },
    components: {
        'notificationlist':NotificationList,
    },
    computed:{
        notifications(){
            var result = this.$store.state.local.unseenNotifications.filter(obj => {
                return (obj.notification_type !== "form-sub" && obj.date_seen == null);
            })
            return result;
        },
        submissions(){
            var result = this.$store.state.local.unseenNotifications.filter(obj => {
                return (obj.notification_type == "form-sub" && obj.date_seen == null);
            })
            return result;
        },
        readNotifications(){
            return this.$store.state.local.seenNotifications;
        },
        ...mapGetters([
            'getValueForCurLanguage'
        ]),
    },
    methods: {
        toggleMenu: function () {
            this.$store.commit('toggleNotifications');
        },
        notificationSeen(ids){
            this.$store.dispatch('markNotificationAsSeen',ids);
        },
        notificationDelete(ids){
            this.$store.dispatch('deleteNotification',ids);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.overlay{
    position: fixed;
    width:100%;
    background: rgb(2,0,36);
    background: linear-gradient(128deg, $bgColorDark 0%, $bgColorMed 100%);
    height: 50000%;
}
#notifications{
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
}
#notifications-content{
    display: flex;
    flex-direction: column;
    text-align: left;
    min-height: 100vh;
    padding:20px;
    padding-bottom:60px;
    padding-top: 45px;
    height: auto;
    position: absolute;
    background-color:$bgColorDark;
    top: 0;
    min-height: 100vh;
    width: 100%;
    
}
</style>