<template>
    <div class="light-wrap">
        <div class="light-green light" :class="status === 1 ? 'active' : ''"></div>
        <div class="light-yellow light"  :class="status === 2 ? 'active' : ''"></div>
        <div class="light-red light"  :class="status === 3 ? 'active' : ''"></div>
    </div>
</template>

<script>

export default {
    props:{
        status:Number,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.light-wrap{
    width:26px;
    height: 68px;
    background-color:grey;
    border-radius:6px;
    border:2px solid black;
    margin:auto;
    .light{
        height: 18px;
        border-radius: 10px;
        border:1px solid black;
        opacity: 1;
        margin: 2px;
        flex:0 0 18px;
    }
    .light-green.active{
        background-color:$trafficLightColorGreen;
    }
    .light-yellow.active{
        background-color:$trafficLightColorYellow;
    }
    .light-red.active{
        background-color:$trafficLightColorRed;
    }
}
.light-wrap.wide{
    width:70px;
    height: 26px;
    display: flex;
}
</style>