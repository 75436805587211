<template>
    <div v-if='this.$store.state.status.footerMsg.active' class="footer-wrap">
        <p :class="['msg-wrap',this.$store.state.status.footerMsg.type]">
            {{this.$store.state.status.footerMsg.text}}
        </p>
    </div>
</template>

<script>

export default {

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.footer-wrap{
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 1001;
    .msg-wrap{
        margin:0;
        padding: 12px 5px;
        background-color: $bgColorMedLessTransparent;
        &.neutral{

        }
        &.success{
            color:$trafficLightColorGreen;
        }
        &.warning{
            color:$trafficLightColorYellow;
        }
        &.error{
            color:$trafficLightColorRed;
        }
    }
}
</style>