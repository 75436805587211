<template>
    <div class="button-wrap">
        <img v-if='imgSrc !== undefined' class="button-icon" :src='imgSrc'/>
        <div v-else-if="buttonText">{{buttonText}}</div>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props:{
        imgSrc:String,
        buttonText:String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.button-wrap{
    width: 90px;
    border-radius: 5px;
    background-color: $bgColorLight;
    padding: 6px;
    margin-top:10px;
    cursor:pointer;
    img{
        display: block;
        width:auto;
        height:30px;
        margin:auto;
    }
    /*&:hover{
        background-color: $bgColorDark;
    }*/
}


</style>