<template>
    <div v-if='active' class="overlay">
        <div class="confirmPopup-wrap">
            <div class="confirmMessage">
                {{msg}}
            </div>
            <div class="buttons-wrap">
                <formButton v-if='this.declineMsg !== undefined' class="decline" :buttonText='declineMsg' v-on:click.native="decline()"></formButton>
                <formButton v-else class="decline" v-on:click.native="decline()" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
                
                
                <formButton v-if='this.confirmMsg !== undefined' class="check" :buttonText='confirmMsg' v-on:click.native="confirm()"></formButton>
                <formButton v-else class="check" v-on:click.native="confirm()" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
export default {
    components:{
        'formButton':Button,
    },
    props:{
        active:Boolean,
        msg:String,
        confirmed:Function,
        declined:Function,
        confirmMsg:String,
        declineMsg:String,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        //bring set value to parent component - decoupling
        confirm(){
            this.confirmed();
        },
        decline(){
            if(this.declined){
                this.declined();
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 1000;
    background-color: $bgColorMedTransparent;
    .confirmPopup-wrap{
        padding:20px;
        position: relative;
        width: 85vw;
        margin: auto;
        top:30%;
        background-color: $bgColorLightLight;
        color:$fontColorMed;
        border-radius: 8px;
        .buttons-wrap{
            display:flex;
            justify-content: space-between;
            padding-top:10px;
        }
    }
}
.buttons-wrap .button-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>