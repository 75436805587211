import { deleteLocalForageInstance } from './storage';
import {HTTP, HTTPGEO, HTTPFILE} from '../helpers/axiosHelper.js';
import  {i18n}  from '@/main.js';


const login = ({ commit }, credentials) => {
    commit('setRefreshCount',true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", credentials.username);
    urlencoded.append("password", credentials.password);
    urlencoded.append("device", credentials.device);
    urlencoded.append("company", credentials.company);
    commit('auth_request')
    return HTTP({
        method: 'post',
        url: '/auth/login/driver',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: urlencoded
    }).then(response => {
        const tokens = {accessToken: response.data.accessToken, refreshToken: response.data.refreshToken}
        commit('auth_success', tokens)
    }).catch(error => {
        console.log(error);
        commit('auth_error')
    });
};
const hardLogout = ({commit,dispatch}) => {
    dispatch('logout').then(()=>{
        commit('hardLogout');
    }).catch(error => {
        console.log("error deleting localStore: ",error);
        //return dispatch('logoutOnServer');
        commit('hardLogout');
        location.reload();
    });
};
const logout = ({commit,dispatch}) => {
    let deletePromise = deleteLocalForageInstance().then(() => {
        //commit('logout')
        //TODO!!! check why the reload is necessary to delete the store. is there a racecondition???
        console.log("deleteLocalForageInstance resolves")
        dispatch('logoutOnServer').then(()=>{
            console.log("logoutOnServer resolves")
            commit('logout');
            location.reload();
        }).catch(error => {
            console.log("error logoutOnServer: ",error);
            commit('logout');
            location.reload();
        });
    }).catch(error => {
        console.log("error deleting localStore: ",error);
        //return dispatch('logoutOnServer');
        commit('logout');
        location.reload();
    });
    return deletePromise;
};
const logoutOnServer = ({commit}) => {
    return new Promise((resolve, reject) => {
        return HTTP({
            method: 'post',
            url: '/auth/logout',
        }).then(() => {
            console.log("logout resolves")
            resolve();
        }).catch(error => {
            console.log("Logout Error: ",error);
            commit('auth_error')
            reject(error);
        });
    });
};
const changePassword = ({dispatch},payload) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('old_pass', payload.old_pass);
    urlencoded.append('new_pass', payload.new_pass);
    urlencoded.append('device', 'device');

    return HTTP({
        method: 'POST',
        url: '/me/set-pass',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: urlencoded,
        redirect: 'follow',
    }).then(() => {
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.passwordChangeApiSuccess"), type:'success'},time:6000});
    }).catch(error => {
        console.log("myError:",error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.passwordChangeApiError"), type:'error'},time:6000});
    });
};
const setStatusMsg = ({commit},status) => {
    if(status){
        commit("set_status_msg",status.values);
        commit("set_status_msg_active",true);
        var time
        if(status.time){
            time = status.time;
        }else{
            time = 3000;
        }
        setTimeout(function(){
            commit("set_status_msg_active",false); 
        }, time);
    }
};
const refreshToken = ({commit, dispatch, state }) => {
    //count refresh tries so token refresh does not run into endless loop
    commit('setRefreshCount', false);
    let myPromise = HTTP({
        method: 'post',
        url: '/auth/refresh',
        data: {"refresh_token":state.tokens.refreshToken},
        redirect: 'follow',
    }).then(response => {
        commit('refreshToken', response.data)
        commit('auth_success', response.data)
        commit('setRefreshCount', true);
    }).catch(error => {
        console.log("error in refreshToken Action: ",error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.authenticationFailedRelog"), type:'warning'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'Authentifizierung beim Server fehlgeschlagen, Bitte neu einloggen.', type:'warning'},time:3000});
        commit('logout',false);
    });
    return myPromise;
};
const getProfileData = ({commit, dispatch}) => {
    return HTTP({
    method: 'get',
        url: '/me',
        redirect: 'follow',
    }).then(response => {
        commit('update_me', response.data)
    }).catch(error => {
        console.log(error);
        //commit('api_error')
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.profileDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Profildaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
    });
};
const getNotifications = ({commit, dispatch}) => {
    //if(state.local.notifications.length <= 0){
        return HTTP({
            method: 'get',
            url: '/me/notifications/list/conversations',
            redirect: 'follow',
        }).then((response) => {
            commit('update_notifications', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    /*}else{
        return HTTP({
            method: 'get',
            url: '/me/notifications/list/unseen',
            redirect: 'follow',
        }).then((response) => {
            commit('update_unseen_notifications', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    }*/
};
/*const getNotificationReplies = ({state, commit, dispatch}) => {
    if(state.local.notificationReplies.length <= 0){
        return HTTP({
            method: 'get',
            url: '/me/notifications/list/sent',
            redirect: 'follow',
        }).then((response) => {
            commit('update_notificationReplies', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    }else{
        return HTTP({
            method: 'get',
            url: '/me/notifications/list/sent/unseen',
            redirect: 'follow',
        }).then((response) => {
            commit('update_notificationReplies', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    }
};*/
const getNotificationDetail = ({state,commit,dispatch}, payload) => {
    console.log("state.user.user_id: ",state.user.user_id)
    console.log("payload.authorId: ",payload.authorId)
    if(state.user.user_id !== payload.authorId){
        return HTTP({
            method: 'get',
            url: 'me/notifications/get/'+payload.notificationId,
            redirect: 'follow',
        }).then((response) => {
            commit('update_notificationDetails', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    }else{
        return HTTP({
            method: 'get',
            url: 'me/notifications/get/sent/'+payload.notificationId,
            redirect: 'follow',
        }).then((response) => {
            commit('update_notificationDetails', response.data)
        }).catch(error => {
            console.log(error);
            //commit('api_error')
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDataError"), type:'error'},time:6000});
        });
    }
};
const markNotificationAsSeen = ({commit, dispatch}, id) => {
    if(id instanceof Array){
        var payload = {
            items: id,
        }
        var raw = JSON.stringify(payload);
        return HTTP({
            method: 'POST',
            url: '/me/notifications/seen',
            headers: {"Content-Type": "application/json"},
            data : raw,
            redirect: 'follow',
        }).then(() => {
            commit('notification_seen',id);
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsSeenDataError"), type:'error'},time:6000});
        });
    }else{
        return HTTP({
            method: 'POST',
            url: '/me/notifications/seen/'+id,
            redirect: 'follow',
        }).then(() => {
            commit('notification_seen',id);
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationSeenDataError"), type:'error'},time:6000});
        });
    }
};
const deleteNotification = ({commit, dispatch}, id) => {
    if(id instanceof Array){
        var payload = {
            items: id,
        }
        var raw = JSON.stringify(payload);
        return HTTP({
            method: 'POST',
            url: '/me/notifications/delete',
            headers: {"Content-Type": "application/json"},
            data : raw,
            redirect: 'follow',
        }).then(() => {
            commit('notification_delete',id);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDeleted"), type:'success'},time:6000});
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationsDeleteError"), type:'error'},time:6000});
        });
    }else{
        return HTTP({
            method: 'DELETE',
            url: '/me/notifications/delete/'+id,
            redirect: 'follow',
        }).then(() => {
            commit('notification_delete',id);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationDeleted"), type:'success'},time:6000});
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationDeleteError"), type:'error'},time:6000});
        });
    }
};
const sendNotificationReply = ({commit, dispatch, getters},reply) => {  
    var payload = {
        notification_title: reply.title, // can also be an array of localized strings
        notification_text: reply.value, // can also be an array of localized strings
    }
    console.log
    var raw = JSON.stringify(payload);
    return HTTP({
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        url: '/me/notifications/'+reply.parent_id+'/reply/',
        redirect: 'follow',
        data: raw
    }).then((response) => {
        if(getters.isFeatureSupportet('notificationReplies')){
            //console.log("response: ",response);
            commit("setNotificationReplie",response.data)
            dispatch("markNotificationAsSeen",reply.notification_id)
        }else{
            dispatch('notificationRelies not supported',{values:{text:i18n.t("messages.tourCompleSuccess"), type:'success'},time:6000});
        }
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.notificationReplyError"), type:'error'},time:6000});
    });
};
const getChecklists = ({state, commit, dispatch}, id) => {
    return HTTP({
        method: 'get',
        //url: '/driver/forms/base/'+id,
        url: '/driver/company/vehicles/'+id+'/forms',
        redirect: 'follow',
    }).then(response => {
        //console.log("getChecklists response.data: ",response.data)
        response.data.forEach(form => {
            commit('add_checklist', form)
            //console.log("form.form_base_id: ",form.form_base_id)
            //console.log("state.local.curCheck.defectsId.baseId: ",state.local.curCheck.defectsId.baseId)
            if(form.form_base_id == state.local.curCheck.defectsId.baseId){
                commit('set_defects_checklistId', form)
            }
            commit('initCheckResults', form)
        })
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.formDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Formulardaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getPersonalForms = ({dispatch,commit}) => {
    return HTTP({
        method: 'get',
        url: '/me/forms',
        redirect: 'follow',
    }).then(response => {
        console.log("getPersonalForms response.data: ",response.data)
        response.data.forEach(form => {
            if(form.form_type !== 'task'){
                let formWithappFormType = {...form, appFormType:"personalForm"}
                commit('add_checklist', formWithappFormType)
                console.log("form.form_base_id: ",form.form_base_id)
                commit('initCheckResults', formWithappFormType)
            }
        })
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("personal " + "messages.formDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Formulardaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTasks = ({dispatch}) => {
    return HTTP({
        method: 'get',
        //url: '/driver/forms/base/'+id,
        url: '/me/tasks/list/ongoing',
        redirect: 'follow',
    }).then(response => {
        if(response.data.rows !== undefined){
            console.log("getTasks response.data.rows: ",response.data.rows)
            response.data.rows.forEach( task => {
                dispatch('getTask',task.form_task_id);
            })
        }
        return response;
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.formDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Formulardaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTask = ({commit, dispatch, getters}, id) => {
    if(id !== undefined){
        return HTTP({
            method: 'get',
            //url: '/driver/forms/base/'+id,
            url: '/me/tasks/'+id,
            redirect: 'follow',
        }).then(response => {
            //console.log("getTask response.data: ",response.data)
            if(getters.getCheckResultByFormId(response.data.form.form_id, id) === undefined){
                let{form, ...newTaskWithoutForm} = response.data
                newTaskWithoutForm
                let formType = {'form_type':"task"};
                let newFormWithTask = {...form, task:newTaskWithoutForm, ...formType}
                if(response.data.completed !== 1){
                    commit('add_checklist', newFormWithTask)
                    commit('initCheckResults', newFormWithTask)
                }
            }
            return response;
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.formDataError"), type:'error'},time:6000});
            //dispatch('setStatusMsg',{values:{text:'API Fehler, Formulardaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
            //commit('api_error')
        });
    }else{
        return Promise.reject("undefined task ID");
    }
};
const getVehiclelist = ({commit, dispatch}) => {
    return HTTP({
        method: 'get',
        url: '/driver/company/vehicles',
        redirect: 'follow',
    }).then(response => {
        commit('update_vehiclelist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.vehicleDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Fahrzeugdaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTrailerlist = ({commit, dispatch}) => {
    return HTTP({
        method: 'get',
        url: '/driver/company/trailers',
        redirect: 'follow',
    }).then(response => {
        commit('update_trailerlist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.trailerDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Anhängerdaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getFinishedTasksList = ({commit,dispatch},page) => {
    //returns paginated tours by driver
    return HTTP({
        method: 'get',
        url: '/me/tasks/list/completed?limit=10&page='+page,
        redirect: 'follow',
    }).then(response => {
        commit('update_finishedtasklist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.taskDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Touren konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getFinishedTask = ({dispatch},taskId) =>{
    //count refresh tries so token refresh does not run into endless loop
    if(taskId !== undefined){
        return HTTP({
            method: 'get',
            url: '/me/tasks/'+taskId,
            redirect: 'follow',
        }).then(response => {
            console.log("response getTask: ",response.data);
            return response;
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.taskError"), type:'error'},time:6000});
            //dispatch('setStatusMsg',{values:{text:'API Fehler, Tour konnen nicht heruntergeladen werden.', type:'error'},time:3000});
            //commit('api_error')
        });
    }else{
        return Promise.reject("undefined task ID");
    }
};
const getTourList = ({commit,dispatch},page) => {
    //returns paginated tours by driver
    return HTTP({
        method: 'get',
        url: '/driver/tours/list?limit=10&page='+page,
        redirect: 'follow',
    }).then(response => {
        commit('update_tourlist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourDataError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Touren konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTour = ({commit,dispatch},tourId) =>{
    //count refresh tries so token refresh does not run into endless loop
    return HTTP({
        method: 'get',
        url: '/driver/tours/'+tourId,
        redirect: 'follow',
    }).then(response => {
        console.log("response getTour: ",response.data);
        commit('update_tourdetails', response.data);
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Tour konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const startTour = ({state,commit, dispatch},vehicleIds) => {
    return new Promise((resolve, reject) => {
        //console.log("state.local.curCheck.curTourId: ",state.local.curCheck.curTourId)
        if(state.local.curCheck.curTourId == -1){
            /*var position;
            navigator.geolocation.getCurrentPosition(
            pos => {
                this.position = pos
            },
            error => {
                console.log(error.message);
            },
            )
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);*/
            var payload = {
            "secondary_vehicle_ids": vehicleIds.secondaryIds,
            "geo_start": null,
            "geo_end": null,
            "date_start": null,
            "date_end": null,
            "vehicle_id": vehicleIds.truckId, // uses the company_id from the token
            }
            var raw = JSON.stringify(payload);
            return HTTP({
                method: 'PUT',
                headers: {"Content-Type": "application/json"},
                data: raw,
                url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/tours/start',
                redirect: 'follow',
            }).then(response => {
                commit('setCurTourId',response.data);
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourStartSuccess"), type:'success'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Tour wurde erfolgreich gestartet', type:'success'},time:3000});
                resolve();
            }).catch(error => {
                console.log(error);
                commit('api_error')
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourStartError"), type:'error'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Neue Tour konnen nicht gestartet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                reject(error);
            });
        }else{
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourAllreadyStartedError"), type:'warning'},time:8000});
            //dispatch('setStatusMsg',{values:{text:'Eine Fahrt läuft bereits, diese muss zuerst abgeschlossen oder abgebrochen werden, bevor eine neue Fahrt gestartet werden kann.', type:'warning'},time:8000});
            reject("WARNING: A tour is already started and still active.")
        }
    })
};
const endTour = ({state, commit, getters, dispatch}) => {
    return HTTP({
        method: 'POST',
        url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/tours/'+state.local.curCheck.curTourId+'/end',
        redirect: 'follow',
    }).then(() => {
        if(getters.isFeatureSupportet('time')){
            console.log("TIMER IS SUPPORTET!!!!");
            dispatch('sendTimers').then(()=>{
                commit('finishCurCheck');
            }).catch(() => {
                commit('api_error')
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourCompleTimerError"), type:'error'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Die Tour konnte nicht abgeschlossen werden (Timer). Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
            });
        }else{
            commit('finishCurCheck');
        }
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourCompleSuccess"), type:'success'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'Neue Tour wurden erfolgreich abgeschlossen', type:'success'},time:3000});
    }).catch(error => {
        console.log(error);
        commit('api_error')
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourCompleError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'Die Tour konnte nicht abgeschlossen werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
        //TODO: wenn tour nich abgeschlossen werden kann, lokal die endzeit zur jeweiligen tourId speichern und später zu pushen
    });
};
const abortTour = ({state, commit, dispatch}) => {
    return HTTP({
        method: 'POST',
        url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/tours/'+state.local.curCheck.curTourId+'/abandon',
        redirect: 'follow',
    }).then(() => {
        commit('finishCurCheck');
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.tourAborted"), type:'success'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'Tour Abgebrochen', type:'success'},time:3000});
    }).catch(error => {
        console.log(error);
        //TODO: wenn tour nich abgeschlossen werden kann, lokal die endzeit zur jeweiligen tourId speichern und später zu pushen
    });
};
const setFormFieldValues = ({state, commit, dispatch, getters}, payload) => {
    console.log("isPersonal at beginning: ",payload.isPersonal)
    return new Promise((resolve, reject) => {
        var promises = [];
        let form = getters.getCheckResultByFormId(payload.formId,payload.taskId,payload.isPersonal);
        if(form !== undefined){
            Object.keys(form.fields).map((key) => {
                if(form.fields[key].type && ["boolean","radio","file"].includes(form.fields[key].type.type)
                    && form.fields[key].options 
                    && form.fields[key].options.length > 0)
                {
                    let options = form.fields[key].options;
                    //only use nessessary props
                    options.map((item,index) => {
                        if(item.type === "file" && 
                        Array.isArray(form.fields[key].options[index].fileObjects) && 
                        form.fields[key].options[index].fileObjects.length > 0)
                        {
                            let fileData = {
                                'formId':payload.formId,
                                'taskId':payload.taskId,
                                'fieldId':key,
                                'optionKey':index,
                                'isPersonal':payload.isPersonal
                            }
                            console.log("fileData:",fileData)
                            var promi = dispatch("sendFiles",fileData);
                            promises.push(promi);
                        }
                    });
                }
                else if(form.fields[key].type && ["signature"].includes(form.fields[key].type.type)){
                    if(Array.isArray(form.fields[key].fileObjects) && form.fields[key].fileObjects.length > 0)
                    {
                        let fileData = {
                            'formId':payload.formId,
                            'taskId':payload.taskId,
                            'fieldId':key,
                            'isPersonal':payload.isPersonal
                        }
                        console.log("fileData:",fileData)
                        var promi = dispatch("sendFiles",fileData);
                        promises.push(promi);
                    }
                }
            });
            
        }
        Promise.all(promises).then(() => {
            var fields = [];
            var result;
            if(state.local.curCheck.curTourId > 0 && !payload.isPersonal){
                result= {
                    tour_id: state.local.curCheck.curTourId,
                    fields: [],
                };
            }else{
                result= {
                    task_id: payload.taskId,
                    fields: [],
                };
            }
            if(form !== undefined){
                fields = Object.keys(form.fields).map((key) => {
                    var rObj = {empty:""};
                    let value = [];
                    if(form.fields[key].type &&  ["boolean","radio","file"].includes(form.fields[key].type.type)
                        && form.fields[key].options 
                        && form.fields[key].options.length > 0)
                    {
                        let options = form.fields[key].options;
                        //only use nessessary props
                        options.map((option,index) => {
                            var serverFileIds = [];
                            if(Array.isArray(option.fileObjects) && option.fileObjects.length > 0){
                                option.fileObjects.map((item)=>{
                                    serverFileIds.push(item.serverFileId);
                                });
                            }
                            if(serverFileIds.length > 0){
                                value.push({option:index, value:option.value, selected:option.selected, files:serverFileIds});
                            }else{
                                value.push({option:index, value:option.value, selected:option.selected});
                            }
                            if(option.type === "file" && 
                            Array.isArray(form.fields[key].options[index].fileObjects) && 
                            form.fields[key].options[index].fileObjects.length > 0){
                                let fileData = {
                                    'formId':payload.formId,
                                    'taskId':payload.taskId,
                                    'fieldId':key,
                                    'optionKey':index,
                                    'isPersonal':payload.isPersonal
                                }
                                console.log("dispatch sendfiles: ",fileData);
                                var promi = dispatch("sendFiles",fileData);
                                promises.push(promi);
                                /*dispatch("sendFile",fileData).catch(error => {
                                    fileSendError = true;
                                    console.log(error);
                                    commit('api_error')
                                    dispatch('setStatusMsg',{values:{text:'Files konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                                    commit('setFormStatusSend',{formId:formId,value:'error'});
                                });*/
                                console.log("file from store: ",form.fields[key].options[index].fileObjects);
                            }
                        });
                        rObj= {'name':key, 'value':value};
                    }else if(form.fields[key].type && ["signature"].includes(form.fields[key].type.type)){
                        let formField = form.fields[key];
                        let serverFileIdsSignature = [];
                        let sigValue = {files:null};
                        if(Array.isArray(formField.fileObjects) && formField.fileObjects.length > 0){
                            formField.fileObjects.map((item)=>{
                                console.log("item.serverFileId: ",item.serverFileId);
                                if(item.serverFileId !== null && item.serverFileId !== undefined){
                                    serverFileIdsSignature.push(item.serverFileId);
                                }
                            });
                            if(serverFileIdsSignature.length > 0){
                                sigValue.files = serverFileIdsSignature;
                            }
                            let fileData = {
                                'formId':payload.formId,
                                'taskId':payload.taskId,
                                'fieldId':key,
                                'isPersonal':payload.isPersonal
                            }
                            console.log("dispatch sendfiles (signature): ",fileData);
                            var promi = dispatch("sendFiles",fileData);
                            promises.push(promi);
                        }
                        rObj= {'name':key, 'value':sigValue};
                    }else{
                        rObj= {'name':key, 'value':form.fields[key].value};
                    }
                    return rObj;
                });
            }
            result.fields = fields
            console.log(fields);
            let url;
            console.log("isPersonal in setFormFieldValues: ",payload.isPersonal)
            console.log("appFormType in setFormFieldValues: ",payload.appFormType)
            if(payload.isPersonal || state.local.curCheck.chosenTruck < 0){
                url = '/driver/forms/'+payload.formId+'/submit';
            }else if(payload.appFormType === "personalForm"){
                //ToDo: use appFormType for all the distinctions 
                //(maybe replace the whole isPersonal Flag and later the different collections like PersonalTasks vs curCheck.tourTasks vs curCheck.tourCheckLists with it)
                url = '/me/forms/'+payload.formId+'/submit';
            }else{
                url = '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/forms/'+payload.formId+'/submit'
            }
            var raw = JSON.stringify(result);
            HTTP({
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                data: raw,
                url: url,
                //url: '/admin/forms/'+formId+'/submit',
                redirect: 'follow',
            }).then(() => {
                commit('setFormStatusSend',{formId:payload.formId,taskId:payload.taskId,value:'sent', isPersonal:payload.isPersonal},);
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.formDataSendSuccess"), type:'success'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Formulardaten wurden erfolgreich gesendet', type:'success'},time:3000});
                if(payload.formId === state.local.curCheck.defectsId.formId){
                    console.log("sent form is defects!!");
                    commit('saveSentDefect', form);
                    commit('initCheckResults', getters.getFormByFormId(payload.formId, payload.taskId, payload.isPersonal));
                    //commit('resetChecklistStatus',{formId:formId, taskId:taskId);
                }
                resolve();
            }).catch(error => {
                commit('api_error');
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.formDataSendError"), type:'error'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Formulardaten konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                commit('setFormStatusSend',{formId:payload.formId,taskId:payload.taskId,value:'error', isPersonal:payload.isPersonal});
                reject(error);
            });
        }).catch((error) => {
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.fileDataSendError")+" "+error, type:'error'},time:6000});
            //dispatch('setStatusMsg',{values:{text:'Dateien konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.'+error, type:'error'},time:3000});
            reject(error);
        });
    })
};
const sendFiles = ({dispatch, getters},fileData) => {
    return new Promise((resolve,reject) => {
        let fileObjects = [];
        let resultField = getters.getCheckResultByFormId(fileData.formId,fileData.taskId, fileData.isPersonal).fields[fileData.fieldId];
        if(resultField.type.type === "signature"){
            fileObjects = resultField.fileObjects;
        }else{
            fileObjects = resultField.options[fileData.optionKey].fileObjects;
        }
        let promises = [];
        fileObjects.forEach(function(item,index){
            console.log("localFileId: ", index)
            console.log("item.dataUrl: ", item.dataUrl)
            if(item.status === "unsent" && item.fileObject.dataUrl !== "" && item.fileObject.dataUrl !== undefined){
                let promi = dispatch("sendFile",{'fileData':fileData,'file':item,'localFileId':index, 'isPersonal':fileData.isPersonal, 'isOption':fileData.optionKey === undefined ? false : true});
                promises.push(promi);
            }
        })
        Promise.all(promises).then(() => {
            resolve();
        }).catch((error)=>{
            reject(error);
        })
    });
};
 const sendFile = ({state, commit},payload) => {
    return new Promise((resolve,reject) => {
        var formdata = new FormData();
        console.log("payload.file: ",payload.file);

        
        var arr = payload.file.fileObject.dataUrl.split(',');
        if(arr[0].match(/:(.*?);/) !== null){
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = window.atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            var file;
            if(payload.file.fileObject.info !== undefined){
                file = new File([u8arr], payload.file.fileObject.info.name, {type:mime});
            }else{
                file = new File([u8arr], "signature.png", {type:mime});
            }
        
            console.log(file);

            formdata.append("image", file, payload.file.fileObject.name);
            //formdata.append("image", fileData.blob, fileData.blob.name);
            console.log("formdata: ",formdata);
            let url;
            if(payload.isPersonal || state.local.curCheck.chosenTruck < 0){
                if(payload.isOption){
                    url = "/driver/forms/"+payload.fileData.formId+"/field/"+payload.fileData.fieldId+"/"+payload.fileData.optionKey+"/upload"
                }else{
                    url = "/driver/forms/"+payload.fileData.formId+"/field/"+payload.fileData.fieldId+"/upload"
                }
            }else{
                if(payload.isOption){
                    url = "/driver/tours/"+state.local.curCheck.curTourId+"/forms/"+payload.fileData.formId+"/field/"+payload.fileData.fieldId+"/"+payload.fileData.optionKey+"/upload"
                }else{
                    url = "/driver/tours/"+state.local.curCheck.curTourId+"/forms/"+payload.fileData.formId+"/field/"+payload.fileData.fieldId+"/upload"
                }        
            }
            HTTPFILE({
                method: 'PUT',
                headers: {"Content-Type": "application/json"},
                data: formdata,
                url:url,
                redirect: 'follow',
            }).then((response) => {
                commit('setServerFileId',{'formId':payload.fileData.formId, 'taskId':payload.fileData.taskId, 'fieldId':payload.fileData.fieldId, 'optionKey':payload.fileData.optionKey, 'serverFileId':response.data, 'localFileId':payload.localFileId, isPersonal:payload.isPersonal});
                resolve();
            }).catch((error) => {
                reject(error);
            })
        }else{
            //dispatch('setStatusMsg',{values:{text:i18n.t("messages.uploadImageError"), type:'error'},time:6000});
            reject(i18n.t("messages.uploadImageError"));
        }
    })
 };
const getDocuImages= ({commit,dispatch},file) =>{
    //count refresh tries so token refresh does not run into endless loop
    return HTTPFILE({
        method: 'get',
        //Retrieves a file. This route skips database lookups.
        //:filename parameter is the file's ID
        //url: '/driver/forms/'+payload.form_id+'/field/'+payload.field_id+'/file/'+payload.filename,
        url: '/driver/files/'+file.file_id,
        responseType: 'arraybuffer',
        redirect: 'follow',
    }).then(response => {
        const buffer = Buffer.from(response.data, 'binary');
        const base = buffer.toString('base64');
        const result = 'data:'+response.headers['content-type']+';base64,'+ base;
        commit('update_docu_image', result);
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.docuImageError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Fehler, Tour konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const sendTimers = ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
        var timerArray = state.local.nachtragTimer.trackedTimesEntries;
        var result = Object.keys(timerArray).map((key) => {
            var endTime;
            if(timerArray[key].endTime){
                endTime = timerArray[key].endTime;
            }else{
                endTime = Date.now();
            }
            var rObj = {
                    "user_id": state.user.user_id,
                    "company_id": state.user.company_id,
                    "tour_id": state.local.curCheck.curTourId,
                    'vehicle_id': state.local.curCheck.chosenTruck,
                    'timer_type': timerArray[key].type, 
                    'timer_start': timerArray[key].startTime,
                    'timer_end': endTime,
                    'timer_description': timerArray[key].text,
                    "mileage": timerArray[key].km,
                    "geo_start":timerArray[key].geoStart,
                    "geo_end":timerArray[key].geoEnd,
                };
            return rObj;
        });
        console.log("result: ",result);
        var raw = JSON.stringify(result);
        //multiple timer entries
        return HTTP({
            method: 'PUT',
            headers: {"Content-Type": "application/json"},
            data: raw,
            url: '/driver/timers/add',
            redirect: 'follow',
        }).then((response) => {
            if(response.data === false){
                commit('setFormStatusFields',{formId:'time',value:'failed'});
                commit('setFormStatusSend',{formId:'time',value:'error',});
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.timerSendErrorInvalidResponse"), type:'error'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Zeiten konnten nicht hochgeladen werden.', type:'error'},time:3000});
                reject();
            }else{
                commit('setFormStatusSend',{formId:'time',value:'sent'});
                dispatch('setStatusMsg',{values:{text:i18n.t("messages.timerSendSuccess"), type:'success'},time:6000});
                //dispatch('setStatusMsg',{values:{text:'Zeiten erfolgreich hochgeladen.', type:'success'},time:3000});
                commit('clearTimers');
                resolve();
            }
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:i18n.t("messages.timerSendError"), type:'error'},time:6000});
            //dispatch('setStatusMsg',{values:{text:'Zeiten konnten nicht hochgeladen werden. Bitte überprüfe deine Internetverbindung', type:'error'},time:3000});
            reject();
        })
    });
}; 
const reverseGeoLookup = ({commit, dispatch}, geolocation) => {
    return HTTPGEO({
        method: 'get',
        url: 'reverse-geocode-client?latitude='+geolocation.coords.latitude+'&longitude='+geolocation.coords.longitude+'&localityLanguage=de',
        redirect: 'follow',
    }).then(response => {
        commit('setAdress', response.data)
    }).catch(error => {
        console.log(error);
        //commit('api_error')
        dispatch('setStatusMsg',{values:{text:i18n.t("messages.checkLocationError"), type:'error'},time:6000});
        //dispatch('setStatusMsg',{values:{text:'API Error, Land konnte nicht ermittelt werden', type:'error'},time:3000});
    });
};

export default{
    login,
    hardLogout,
    logout,
    logoutOnServer,
    setStatusMsg,
    refreshToken,
    getProfileData,
    getNotifications,
    getNotificationDetail,
    markNotificationAsSeen,
    deleteNotification,
    sendNotificationReply,
    getChecklists,
    getPersonalForms,
    getTasks,
    getTask,
    getVehiclelist,
    getTrailerlist,
    getFinishedTasksList,
    getFinishedTask,
    getTourList,
    getTour,
    startTour,
    endTour,
    abortTour,
    setFormFieldValues,
    sendTimers,
    reverseGeoLookup,
    sendFiles,
    sendFile,
    getDocuImages,
    changePassword,
}