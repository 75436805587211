import Vue from "vue";
import Vuex from "vuex";

import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import cache from './plugins/cache';
import sync from './plugins/sync.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //flag to see if store allready was loaded from cache initially, if not load data from cache
        initialized: false,
        isLoggedIn:false,
        keepData:false,
        tokens:{
            accessToken:'',
            refreshToken:''
        },
        features:{
            //featureArray:['time',"timeCountry","timeMileage","toDo","notifications","notificationReplies"],
            featureArray:['time',"timeCountry","toDo","notifications","notificationReplies"]
        },
        status:{
            value:'',
            footerMsg:{
                text:'',
                //error, warning, success, neutral: 
                type:'neutral',
                active:false,
            },
            optionsPopup:{
                active:false,
                field:{},
                option:-1,
            },
            lockPopup:{
                active:false,
                field:{},
                type:"",
            },
            docuDetail:{
                active:false,
                submissions:[],
                vehicle:{},
                secondaryVehicles:[],
                timers:[]
            },
            refreshCount:0,
        },
        isNavOpen:false,
        isNotificationsOpen:false,
        user:{
            firstname:'',
            lastname: '', 
            street: '/',
            nr: '/',
            plz: '/',
            city: '/',
            email: '/',
            count: 0,
            licenseDate: 324,
            preDriveChecks: 0,
            posDriveChecks: 0,
            neutralDriveChecks: 0,
            negDriveChecks: 0,
            languageCode:0,
        },
        vehiclesAPI:{
            vehicles:{},
            trailers:{},
        },
        local:{
            sentDefects:[],
            nachtragTimer:{
                currentlyTracked:'none',
                trackedTimesEntries:[],
                timerId:0,
                startTimeActualTimer: -1,
                country:undefined,
            },
            curCheck:{
                lkwDataId:'lkw',
                timeId:'time',
                defectsId:{
                    baseId:-1,
                    formId:-1
                },
                tourCheckLists:[],
                tourCheckResults:[
                    {formId:"lkw" , status:{fieldStatus:'open', sendStatus:'open', mandatory:true}},
                    {formId:"time", status:{fieldStatus:'open', sendStatus:'open', mandatory:false}}
                ],
                tourTasks:[],
                tourTaskResults:[],
                startTime:undefined,
                chosenTruck: -1,
                chosenTrailer: -1,
                curTourId: -1,
                vehicleType:1,
            },
            personalTaskResults:[],
            personalTasks:[],
            documentation:{
                finishedTours:{},
                finishedToursData:{
                    activePage:1,
                    pageCount:1
                },
                finishedTasks:{},
                finishedTasksData:{
                    activePage:1,
                    pageCount:1
                },
                tourDetails:{
                    submissions:[],
                    vehicle:{},
                    secondaryVehicles:[],
                    timers:[]
                },
                currentShownImages:[]
            },
            unseenNotifications:[],
            seenNotifications:[],
        },
    },
    getters,
    mutations,
    actions,
    plugins: [cache, sync],
    modules: {},
});
