const plugin = (store) => {
    store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case '..':
          // dispatch request
          //SoNo: delete this output its only there, that var is not unused
          console.log("state:", state);
          break;
        case '...':
          // dispatch request
          break;  
        default:
        // Ignore default case
      }
    });
  };
  
  export default plugin;